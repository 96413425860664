import {Injectable} from '@angular/core';
import {NEVER, Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {APIMessages as APIM} from '@common/utils/dist/index.js';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {ReceiveEventAcknowledgementAction} from '@actions/receive-event-acknowledgement.action';
import {WSMessageReceiverService} from '@services/ws-message-receiver.service';

@Injectable()
export class AcknowledgedMessageProcessor implements WebsocketMessageProcessor {
  constructor(
    private receiveEventAcknowledgementAction: ReceiveEventAcknowledgementAction,
    private wsMessageReceiverService: WSMessageReceiverService,
  ) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params.msg;
    if (msg.int === APIM.RequestIntent.AppAcknowledge) {
      params.wsAPI.acknowledge(msg.id);

      return this.wsMessageReceiverService
        .receive<APIM.EntryEventRequest>(msg)
        .pipe(
          mergeMap((request) =>
            this.receiveEventAcknowledgementAction.execute({
              payload: request.c,
              error: msg.e,
            }),
          ),
        );
    }
    return NEVER;
  }
}
