import { UtilsPL2 as U } from '../utils/utils-pl2.js';
export class DefaultDecisionsBuilder {
    constructor() { }
    buildDecisions(context) {
        const decisions = {};
        const email = context?.email;
        const testEmailParts = ['_test', 'pltest'];
        decisions.syncGoogleClassesGreedily = true;
        if (!U.isEmpty(email) && testEmailParts.some((p) => email.includes(p))) {
            decisions.useReassignLesson = true;
            decisions.generateLesson = true;
            decisions.useMultiVersionAssignment = true;
        }
        return decisions;
    }
}
export class EnableMeDecisionsBuilder {
    constructor(me) {
        this.me = me;
    }
    buildDecisions(context) {
        const decisions = {};
        const email = context?.email;
        decisions.syncGoogleClassesGreedily = true;
        if (!U.isEmpty(email) && email === this.me) {
            decisions.useReassignLesson = true;
            decisions.generateLesson = true;
            decisions.useMultiVersionAssignment = true;
        }
        return decisions;
    }
}
