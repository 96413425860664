import { CommProtocol, DigitalPowerMode, I2CClockSpeed, } from './connection-types.js';
import * as OdysseyCommandCodes from './odyssey-command-codes.js';
import * as PLCommandCodes from './pl-command-codes.js';
import { SensorDriverHandler } from './sensor-driver-handler.js';
// Device control commands 0x0x
export const WHO_AM_I = 0x01;
// heartrate cmds 0x1x
export const READ_HEARTRATE = 0x10; // responds with biodata.heartRate, read only
export const READ_CONFIDENCE = 0x11; // responds with biodata.confidence, read only
export const READ_BLOOD_OX = 0x12; // responds with biodata.oxygen, read only
export const READ_STATUS = 0x13; // responds with biodata.status, read only
export const READ_ALL = 0x14; // responds with all four of the above, read only
// spectroscopy cmds 0x2x
export const READ_CAL_CHNL_DATA = 0x20; // responds with calibrated values from all 18 channels, read only
export const READ_RAW_CHNL_DATA = 0x21; // responds with raw values from all 18 channels, read only
// air velocity cmds 0x3x
export const READ_VELOCITY = 0x30; // responds with flow_sensor.flow_data, read only
// load cell cmds 0x4x
export const READ_WEIGHT = 0x40; // responds with the latest adc conversion
// soil moisture cmds 0x5x
export const READ_MOISTURE_LVL = 0x50; // responds with the latest adc conversion
// data lengths
export const WHO_AM_I_SIZE = 2;
export const HEARTRATE_SIZE = 2;
export const CONFIDENCE_SIZE = 1;
export const BLOOD_OX_SIZE = 2;
export const STATUS_SIZE = 1;
export const ALL_SIZE = 7;
export const CAL_CHNL_DATA_SIZE = 38; // 1 register byte, 1 byte to indicate first or second half of data, then 36 bytes of data (9 floats)
export const RAW_CHNL_DATA_SIZE = 37; // 1 register byte then 36 bytes (18 uint16_t) of data
export const FLOW_DATA_SIZE = 5;
export const SOIL_MOISTURE_ADC_CONV_SIZE = 2;
export const MIN_I2C_POLLING_INTERVAL = 100;
export const MAX_12C_POLLING_INTERVAL = 65535;
const validRegisters = new Set([
    WHO_AM_I,
    READ_HEARTRATE,
    READ_CONFIDENCE,
    READ_BLOOD_OX,
    READ_STATUS,
    READ_ALL,
    READ_CAL_CHNL_DATA,
    READ_RAW_CHNL_DATA,
    READ_VELOCITY,
    READ_WEIGHT,
    READ_MOISTURE_LVL,
]);
export const ExtSensorRapidProtoRegisters = [
    {
        name: 'WHO_AM_I',
        value: 0x01,
        size: WHO_AM_I_SIZE,
    },
    {
        name: 'READ_HEARTRATE',
        value: 0x10,
        size: HEARTRATE_SIZE,
    },
    {
        name: 'READ_CONFIDENCE',
        value: 0x11,
        size: CONFIDENCE_SIZE,
    },
    {
        name: 'READ_BLOOD_OX',
        value: 0x12,
        size: BLOOD_OX_SIZE,
    },
    {
        name: 'READ_STATUS',
        value: 0x13,
        size: STATUS_SIZE,
    },
    {
        name: 'READ_ALL',
        value: 0x14,
        size: ALL_SIZE,
    },
    {
        name: 'READ_CAL_CHNL_DATA',
        value: 0x20,
        size: CAL_CHNL_DATA_SIZE,
    },
    {
        name: 'READ_RAW_CHNL_DATA',
        value: 0x21,
        size: RAW_CHNL_DATA_SIZE,
    },
    {
        name: 'READ_VELOCITY',
        value: 0x30,
        size: FLOW_DATA_SIZE,
    },
    {
        name: 'READ_WEIGHT',
        value: 0x40,
        size: 0, // TODO: implement load cell driver
    },
    {
        name: 'READ_MOISTURE_LVL',
        value: 0x50,
        size: SOIL_MOISTURE_ADC_CONV_SIZE,
    },
];
// ext sensor rapid proto sensor return codes
export var ExtSensorRapidProtoRetcode;
(function (ExtSensorRapidProtoRetcode) {
    ExtSensorRapidProtoRetcode[ExtSensorRapidProtoRetcode["EXT_SENSOR_RAPID_PROTO_SUCCESS"] = 0] = "EXT_SENSOR_RAPID_PROTO_SUCCESS";
    ExtSensorRapidProtoRetcode[ExtSensorRapidProtoRetcode["EXT_SENSOR_RAPID_PROTO_ERR_DATA_EMPTY"] = 1] = "EXT_SENSOR_RAPID_PROTO_ERR_DATA_EMPTY";
    ExtSensorRapidProtoRetcode[ExtSensorRapidProtoRetcode["EXT_SENSOR_RAPID_PROTO_ERR_UNRECOGNIZED_CMD"] = 2] = "EXT_SENSOR_RAPID_PROTO_ERR_UNRECOGNIZED_CMD";
})(ExtSensorRapidProtoRetcode || (ExtSensorRapidProtoRetcode = {}));
export class ExtSensorRapidProtoDriver extends SensorDriverHandler {
    constructor() {
        super();
        this.err_code = ExtSensorRapidProtoRetcode.EXT_SENSOR_RAPID_PROTO_SUCCESS;
    }
    async extSensorRapidProtoTX(tx, size) {
        if (this.commandQueue == null) {
            throw new Error('extSensorRapidProtoTX - connection object is null');
        }
        if (tx == null || size < 1) {
            throw new Error('extSensorRapidProtoTX - invalid command');
        }
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_WRITE,
            this.interfaceID,
            size,
            ...tx,
        ]);
    }
    // regAddr - data to read
    // size - number of bytes to read
    async extSensorRapidProtoReadData(regAddr, size) {
        if (this.commandQueue == null) {
            throw new Error('extSensorRapidProtoReadData - connection object is null');
        }
        if (regAddr == null || !validRegisters.has(regAddr)) {
            throw new Error('extSensorRapidProtoReadData - invalid register address');
        }
        // write the register to read
        await this.extSensorRapidProtoTX([regAddr], 1);
        // send i2c read command
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY,
            this.interfaceID,
            size,
        ]);
        // send READ_DIGITAL_INTERFACE_X cmd
        if (this.interfaceID == 1) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_1 << 1) + 1,
            ]);
        }
        else if (this.interfaceID == 2) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_2 << 1) + 1,
            ]);
        }
        else {
            throw new Error('extSensorRapidProtoReadData - invalid interface ID');
        }
        // read back and process response
        await this.commandQueue.addRead().then((result) => {
            this.data = [];
            for (let i = 2; i < result.byteLength; i++) {
                this.data.push(result.getUint8(i));
            }
            this.processData();
        });
    }
    async init(commandQueue, port, customParams) {
        this.commandQueue = commandQueue;
        this.interfaceID = port;
        this.err_code = ExtSensorRapidProtoRetcode.EXT_SENSOR_RAPID_PROTO_SUCCESS;
        this.i2c_polling_register = customParams.polling_reg;
        this.i2c_polling_size = customParams.polling_length;
        // heartrate
        this.heartrate = 0;
        this.confidence = 0;
        this.blood_oxygen = 0;
        this.status = 0;
        // spectroscopy
        this.cal_chnl_data = [];
        this.raw_chnl_data = [];
        // clear rx and tx buffers
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.CLEAR_BUFFER,
            this.interfaceID,
        ]);
        this.err_code = await this.disable_polling();
        // read the WHO AM I register
        await this.extSensorRapidProtoReadData(WHO_AM_I, WHO_AM_I_SIZE);
        this.err_code = await this.set_polling_interval(1000);
        this.err_code = await this.set_polling_mode(OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY);
        // await this.extSensorRapidProtoReadData(); // send a read command of the desired data
        this.err_code = await this.enable_polling();
        if (this.err_code == ExtSensorRapidProtoRetcode.EXT_SENSOR_RAPID_PROTO_SUCCESS) {
            return true;
        }
        else {
            return false;
        }
    }
    // need to disable polling when clicking the disconnect button
    async disconnect() {
        await this.disable_polling();
    }
    async enable_polling() {
        if (this.i2c_polling_interval == null ||
            this.i2c_polling_interval == 0 ||
            this.i2c_polling_mode == null) {
            throw new Error('ext sensor rapid proto enable_polling - polling interval and mode arent set so cannot enable polling');
        }
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.ON,
        ]);
        return this.err_code;
    }
    async disable_polling() {
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.OFF,
        ]);
        return this.err_code;
    }
    // interval (uint16_t) is in ms
    async set_polling_interval(interval) {
        if (interval < MIN_I2C_POLLING_INTERVAL ||
            interval > MAX_12C_POLLING_INTERVAL) {
            throw new Error('ext sensor rapid proto set_polling_interval - invalid i2c polling interval');
        }
        this.i2c_polling_interval = interval;
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL,
            this.interfaceID,
            this.i2c_polling_interval & 0xff,
            (this.i2c_polling_interval >> 8) & 0xff,
        ]);
        return this.err_code;
    }
    // mode is the given operation to poll
    async set_polling_mode(mode) {
        if (mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_READ ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_WRITE ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE) {
            throw new Error('ext sensor rapid proto set_polling_mode - invalid i2c polling mode');
        }
        this.i2c_polling_mode = mode; // type is defined so any call to this that gives a nonexistent command code doesn't compile
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE,
            this.interfaceID,
            this.i2c_polling_mode,
        ]);
        return this.err_code;
    }
    processData(data = null) {
        if (data != null) {
            this.data = [];
            this.data = data;
        }
        else {
            return [];
        }
        if (this.data.length == 0) {
            this.err_code =
                ExtSensorRapidProtoRetcode.EXT_SENSOR_RAPID_PROTO_ERR_DATA_EMPTY;
            console.error(`Ext Sensor Rapid Proto data empty, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        let uint8Arr = new Uint8Array(this.data);
        let buffer = uint8Arr.buffer;
        let dataView = new DataView(buffer);
        let i = 0;
        let exitLoop = false;
        while (!exitLoop && i < this.data.length) {
            switch (this.data[i]) {
                case WHO_AM_I:
                    i++;
                    let addr = dataView.getUint8(i);
                    if (i != ExtSensorRapidProto.commConfig.address) {
                        console.error(`WHO AM I register gave wrong I2C address: ${addr}`);
                    }
                    exitLoop = true;
                    break;
                case READ_CAL_CHNL_DATA:
                    i++;
                    if (this.data[i] == 1) {
                        i++;
                        for (let j = 0; j < 9; j++) {
                            this.cal_chnl_data[j] = dataView.getFloat32(i, true);
                            i += 4;
                        }
                    }
                    else if (this.data[i] == 2) {
                        i++;
                        for (let j = 9; j < 18; j++) {
                            this.cal_chnl_data[j] = dataView.getFloat32(i, true);
                            i += 4;
                        }
                    }
                    this.data = []; // reset data
                    let x = 0;
                    return [
                        // return calibrated values
                        ['calibrated a', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated b', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated c', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated d', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated e', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated f', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated g', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated h', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated r', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated i', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated s', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated j', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated t', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated u', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated v', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated w', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated k', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated l', this.cal_chnl_data[x++], 'µW/cm²'],
                    ];
                case READ_RAW_CHNL_DATA:
                    i++;
                    for (let j = 0; j < 18; j++) {
                        this.raw_chnl_data[j] = dataView.getUint16(i, true);
                        i += 2;
                    }
                    this.data = []; // reset data
                    let y = 0;
                    return [
                        // return raw adc values
                        ['raw a', this.raw_chnl_data[y++], 'counts'],
                        ['raw b', this.raw_chnl_data[y++], 'counts'],
                        ['raw c', this.raw_chnl_data[y++], 'counts'],
                        ['raw d', this.raw_chnl_data[y++], 'counts'],
                        ['raw e', this.raw_chnl_data[y++], 'counts'],
                        ['raw f', this.raw_chnl_data[y++], 'counts'],
                        ['raw g', this.raw_chnl_data[y++], 'counts'],
                        ['raw h', this.raw_chnl_data[y++], 'counts'],
                        ['raw r', this.raw_chnl_data[y++], 'counts'],
                        ['raw i', this.raw_chnl_data[y++], 'counts'],
                        ['raw s', this.raw_chnl_data[y++], 'counts'],
                        ['raw j', this.raw_chnl_data[y++], 'counts'],
                        ['raw t', this.raw_chnl_data[y++], 'counts'],
                        ['raw u', this.raw_chnl_data[y++], 'counts'],
                        ['raw v', this.raw_chnl_data[y++], 'counts'],
                        ['raw w', this.raw_chnl_data[y++], 'counts'],
                        ['raw k', this.raw_chnl_data[y++], 'counts'],
                        ['raw l', this.raw_chnl_data[y++], 'counts'],
                    ];
                case READ_HEARTRATE:
                    i++;
                    this.heartrate = dataView.getUint16(i, true);
                    exitLoop = true;
                    break;
                case READ_CONFIDENCE:
                    i++;
                    this.confidence = this.data[i];
                    exitLoop = true;
                    break;
                case READ_BLOOD_OX:
                    i++;
                    this.blood_oxygen = dataView.getUint16(i, true);
                    exitLoop = true;
                    break;
                case READ_STATUS:
                    i++;
                    this.status = this.data[i];
                    exitLoop = true;
                    break;
                case READ_ALL:
                    i++;
                    this.heartrate = dataView.getUint16(i, true);
                    i += 2;
                    this.confidence = this.data[i];
                    i++;
                    this.blood_oxygen = dataView.getUint16(i, true);
                    i += 2;
                    this.status = this.data[i];
                    exitLoop = true;
                    break;
                default:
                    // Handle any other unrecognized command
                    this.err_code =
                        ExtSensorRapidProtoRetcode.EXT_SENSOR_RAPID_PROTO_ERR_UNRECOGNIZED_CMD;
                    i++;
                    break;
            }
        }
        // reset data
        this.data = [];
        // return values
        return [];
    }
}
export const ExtSensorRapidProto = {
    name: 'Ext Sensor Rapid Proto Board',
    pwrEn: true,
    pwrMode: DigitalPowerMode.DIGITAL_RAIL_3V3,
    comm: CommProtocol.I2C_External,
    ioEnable: true,
    commConfig: {
        address: 0x7a,
        baudRate: I2CClockSpeed.I2C_CLK_100K,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: new ExtSensorRapidProtoDriver(),
};
