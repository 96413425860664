import {Injectable} from '@angular/core';

import {Store} from '@stores/abstract-store';

export interface LoaderState {
  task: string;
  labels: string[];
}

@Injectable({
  providedIn: 'root',
})
export class LoaderStore extends Store<LoaderState> {
  constructor() {
    super({
      task: null,
      labels: [],
    });
  }

  start(task: string, labels: string[] = []) {
    this.setState({task, labels});
  }

  stop() {
    this.setState({
      task: null,
      labels: [],
    });
  }
}
