export const CalType = {
    Input: 'InputCalibration',
    Compass: 'CompassCalibration',
    Click: 'ClickCalibration',
    Probe: 'ProbeCalibration',
    Auxiliary: 'AuxiliaryCalibration',
};
export var SensorType;
(function (SensorType) {
    SensorType[SensorType["Standard"] = 0] = "Standard";
    SensorType[SensorType["Encoder"] = 1] = "Encoder";
    SensorType[SensorType["Digital"] = 2] = "Digital";
})(SensorType || (SensorType = {}));
const cozirCalibrations = [
    {
        calibrationId: 'air-cozir_calibrate',
        type: CalType.Click,
    },
];
export class ServiceConfigs {
}
// Voyager
ServiceConfigs.mpu9250 = {
    id: 'mpu9250',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa14-0452-4000-b000-000000000000',
    data: 'f000aa15-0452-4000-b000-000000000000',
    memoryId: 1,
    valueLength: [18],
};
ServiceConfigs.vlmpu9250 = Object.assign({
    id: 'vlmpu9250',
}, ServiceConfigs.mpu9250);
ServiceConfigs.bme280 = {
    id: 'bme280',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa16-0452-4000-b000-000000000000',
    data: 'f000aa17-0452-4000-b000-000000000000',
    memoryId: 2,
    valueLength: [8],
};
ServiceConfigs.apds9301 = {
    id: 'apds9301',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa18-0452-4000-b000-000000000000',
    data: 'f000aa19-0452-4000-b000-000000000000',
    memoryId: 4,
    valueLength: [4],
};
ServiceConfigs.vl53l0x = {
    id: 'vl53l0x',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa12-0452-4000-b000-000000000000',
    data: 'f000aa13-0452-4000-b000-000000000000',
    valueLength: [2],
    memoryId: 8,
};
ServiceConfigs.ntc10k = {
    id: 'ntc10k',
    service: 'f000aa11-0452-4000-b000-000000000000',
    data: 'f000aa1a-0452-4000-b000-000000000000',
    config: 'f000aa1b-0452-4000-b000-000000000000',
    memoryId: 16,
    valueLength: [2],
};
// PL1
ServiceConfigs.pl1acc = {
    id: 'pl1acc',
    service: 'f000aa10-0451-4000-b000-000000000000',
    data: 'f000aa11-0451-4000-b000-000000000000',
    config: 'f000aa12-0451-4000-b000-000000000000',
    period: 'f000aa13-0451-4000-b000-000000000000',
};
ServiceConfigs.pl1mag = {
    id: 'pl1mag',
    service: 'f000aa30-0451-4000-b000-000000000000',
    data: 'f000aa31-0451-4000-b000-000000000000',
    config: 'f000aa32-0451-4000-b000-000000000000',
    period: 'f000aa33-0451-4000-b000-000000000000',
};
ServiceConfigs.pl1bar = {
    id: 'pl1bar',
    service: 'f000aa40-0451-4000-b000-000000000000',
    data: 'f000aa41-0451-4000-b000-000000000000',
    config: 'f000aa42-0451-4000-b000-000000000000',
    calibration: 'f000aa43-0451-4000-b000-000000000000',
    period: 'f000aa44-0451-4000-b000-000000000000',
};
ServiceConfigs.pl1gyr = {
    id: 'pl1gyr',
    service: 'f000aa50-0451-4000-b000-000000000000',
    data: 'f000aa51-0451-4000-b000-000000000000',
    config: 'f000aa52-0451-4000-b000-000000000000',
    period: 'f000aa53-0451-4000-b000-000000000000',
    configValue: 7,
};
ServiceConfigs.vlpl1gyr = Object.assign({
    id: 'vlpl1gyr',
}, ServiceConfigs.pl1gyr);
// Non-sensor addresses
ServiceConfigs.uart = {
    id: 'uart',
    service: 'f000aa31-0452-4000-b000-000000000000',
    config: 'f000aa32-0452-4000-b000-000000000000',
    data: 'f000aa33-0452-4000-b000-000000000000',
};
ServiceConfigs.voybat = {
    id: 'voybat',
    service: 0x180f,
    config: 0x2a19,
    data: 'NA',
};
ServiceConfigs.voyname = {
    id: 'voyname',
    service: 'f000aa31-0452-4000-b000-000000000000',
    config: 'f000aa32-0452-4000-b000-000000000000',
    data: 'NA',
};
ServiceConfigs.onboardacc = {
    id: 'onboardacc',
    class: 'Accelerometer',
    data: 'Accelerometer',
};
ServiceConfigs.onboardacclin = {
    id: 'onboardacclin',
    class: 'LinearAccelerationSensor',
    data: 'LinearAccelerationSensor',
};
ServiceConfigs.onboardgyr = {
    id: 'onboardgyr',
    class: 'Gyroscope',
    data: 'Gyroscope',
};
ServiceConfigs.onboardmag = {
    id: 'onboardmag',
    class: 'Magnetometer',
    data: 'Magnetometer',
};
ServiceConfigs.onboardlig = {
    id: 'onboardlig',
    class: 'AmbientLightSensor',
    data: 'AmbientLightSensor',
};
ServiceConfigs.onboardmic = {
    id: 'onboardmic',
    data: 'Audio',
};
ServiceConfigs.plantower = {
    id: 'plantower',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa12-0452-4000-b000-000000000000',
    data: 'f000aa14-0452-4000-b000-000000000000',
    configValue: 3,
    memoryId: 2,
    valueLength: [12],
};
ServiceConfigs.cozir = {
    id: 'cozir',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa12-0452-4000-b000-000000000000',
    data: 'f000aa13-0452-4000-b000-000000000000',
    configValue: 2,
    memoryId: 1,
    valueLength: [18],
    calibrations: cozirCalibrations,
};
ServiceConfigs.ozone = {
    id: 'ozone',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa12-0452-4000-b000-000000000000',
    data: 'f000aa17-0452-4000-b000-000000000000',
    configValue: 6,
    memoryId: 16,
    valueLength: [2],
};
ServiceConfigs.airBar = {
    id: 'airBar',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa12-0452-4000-b000-000000000000',
    data: 'f000aa15-0452-4000-b000-000000000000',
    configValue: 4,
    memoryId: 4,
    valueLength: [8],
};
ServiceConfigs.airLig = {
    id: 'airLig',
    service: 'f000aa11-0452-4000-b000-000000000000',
    config: 'f000aa12-0452-4000-b000-000000000000',
    data: 'f000aa16-0452-4000-b000-000000000000',
    configValue: 5,
    memoryId: 8,
    valueLength: [4],
};
ServiceConfigs.plantower0000 = {
    id: 'plantower',
    service: '0000ab11-0000-1000-8000-00805f9b34fb',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab14-0452-4000-b000-000000000000',
    configValue: 3,
    memoryId: 2,
    valueLength: [12],
};
ServiceConfigs.cozir0000 = {
    id: 'cozir',
    service: '0000ab11-0000-1000-8000-00805f9b34fb',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab13-0452-4000-b000-000000000000',
    configValue: 2,
    memoryId: 1,
    valueLength: [18],
    calibrations: cozirCalibrations,
};
ServiceConfigs.ozone0000 = {
    id: 'ozone',
    service: '0000ab11-0000-1000-8000-00805f9b34fb',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab17-0452-4000-b000-000000000000',
    configValue: 6,
    memoryId: 16,
    valueLength: [2],
};
ServiceConfigs.airBar0000 = {
    id: 'airBar',
    service: '0000ab11-0000-1000-8000-00805f9b34fb',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab15-0452-4000-b000-000000000000',
    configValue: 4,
    memoryId: 4,
    valueLength: [8],
};
ServiceConfigs.airLig0000 = {
    id: 'airLig',
    service: '0000ab11-0000-1000-8000-00805f9b34fb',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab16-0452-4000-b000-000000000000',
    configValue: 5,
    memoryId: 8,
    valueLength: [4],
};
ServiceConfigs.plantower8e40 = {
    id: 'plantower',
    service: '8e40ab11-f315-4f60-9fb8-838830daea50',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab14-0452-4000-b000-000000000000',
    configValue: 3,
    memoryId: 2,
    valueLength: [12],
};
ServiceConfigs.cozir8e40 = {
    id: 'cozir',
    service: '8e40ab11-f315-4f60-9fb8-838830daea50',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab13-0452-4000-b000-000000000000',
    configValue: 2,
    memoryId: 1,
    valueLength: [18],
    calibrations: cozirCalibrations,
};
ServiceConfigs.ozone8e40 = {
    id: 'ozone',
    service: '8e40ab11-f315-4f60-9fb8-838830daea50',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab17-0452-4000-b000-000000000000',
    configValue: 6,
    memoryId: 16,
    valueLength: [2],
};
ServiceConfigs.airBar8e40 = {
    id: 'airBar',
    service: '8e40ab11-f315-4f60-9fb8-838830daea50',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab15-0452-4000-b000-000000000000',
    configValue: 4,
    memoryId: 4,
    valueLength: [8],
};
ServiceConfigs.airLig8e40 = {
    id: 'airLig',
    service: '8e40ab11-f315-4f60-9fb8-838830daea50',
    config: 'f000ab12-0452-4000-b000-000000000000',
    data: 'f000ab16-0452-4000-b000-000000000000',
    configValue: 5,
    memoryId: 8,
    valueLength: [4],
};
ServiceConfigs.plantowercdea = {
    id: 'plantower',
    service: 'cdeaab11-f302-441c-88b3-888248eaa059',
    config: 'cdeaab12-f302-441c-88b3-888248eaa059',
    data: 'cdeaab14-f302-441c-88b3-888248eaa059',
    configValue: 3,
    memoryId: 2,
    valueLength: [12],
};
ServiceConfigs.cozircdea = {
    id: 'cozir',
    service: 'cdeaab11-f302-441c-88b3-888248eaa059',
    config: 'cdeaab12-f302-441c-88b3-888248eaa059',
    data: 'cdeaab13-f302-441c-88b3-888248eaa059',
    configValue: 2,
    memoryId: 1,
    valueLength: [18],
    calibrations: cozirCalibrations,
};
ServiceConfigs.ozonecdea = {
    id: 'ozone',
    service: 'cdeaab11-f302-441c-88b3-888248eaa059',
    config: 'cdeaab12-f302-441c-88b3-888248eaa059',
    data: 'cdeaab17-f302-441c-88b3-888248eaa059',
    configValue: 6,
    memoryId: 16,
    valueLength: [2],
};
ServiceConfigs.airBarcdea = {
    id: 'airBar',
    service: 'cdeaab11-f302-441c-88b3-888248eaa059',
    config: 'cdeaab12-f302-441c-88b3-888248eaa059',
    data: 'cdeaab15-f302-441c-88b3-888248eaa059',
    configValue: 4,
    memoryId: 4,
    valueLength: [8],
    expectedVals: [],
    toleranceRange: 0.005,
    defaultVal: 0,
};
ServiceConfigs.airLigcdea = {
    id: 'airLig',
    service: 'cdeaab11-f302-441c-88b3-888248eaa059',
    config: 'cdeaab12-f302-441c-88b3-888248eaa059',
    data: 'cdeaab16-f302-441c-88b3-888248eaa059',
    configValue: 5,
    memoryId: 8,
    valueLength: [4],
    expectedVals: [],
    toleranceRange: 0.005,
    defaultVal: 0,
};
ServiceConfigs.uartcdea = {
    id: 'uart',
    service: 'cdeaab11-f302-441c-88b3-888248eaa059',
    config: 'cdeaab12-f302-441c-88b3-888248eaa059',
    data: 'cdeaab18-f302-441c-88b3-888248eaa059',
};
ServiceConfigs.airname = {
    id: 'airname',
    service: 'cdeaab11-f302-441c-88b3-888248eaa059',
    config: 'cdeaab12-f302-441c-88b3-888248eaa059',
    data: 'NA',
};
ServiceConfigs.dfu1 = {
    id: 'dfu1',
    data: 'NA',
    service: '8e400001-f315-4f60-9fb8-838830daea50',
};
ServiceConfigs.dfu2 = {
    id: 'dfu2',
    data: 'NA',
    service: 0xfe59,
};
ServiceConfigs.dfu3 = {
    id: 'dfu3',
    data: 'NA',
    service: '0000fe59-0000-1000-8000-00805f9b34fb',
};
ServiceConfigs.pl4tempprobe = {
    id: 'pl4tempprobe',
    service: '3e1c0001-f453-11ea-adc1-0242ac120002',
    data: '3e1c0003-f453-11ea-adc1-0242ac120002',
    config: '3e1c0002-f453-11ea-adc1-0242ac120002', // 0: disable, 1: enable
};
ServiceConfigs.pl42tempprobe = {
    ...ServiceConfigs.pl4tempprobe,
    id: 'pl42tempprobe',
};
ServiceConfigs.pl4name = {
    id: 'pl4name',
    service: '3e1c0001-f453-11ea-adc1-0242ac120002',
    config: '3e1c0002-f453-11ea-adc1-0242ac120002',
    data: 'NA',
};
// used to read firmware revision number from Bluteooth SIG defined characteristic
ServiceConfigs.firmwareinfo = {
    id: 'firmwareinfo',
    service: '0000180a-0000-1000-8000-00805f9b34fb',
    config: 'firmware_revision_string',
    data: 'NA',
};
// used to read hardware revision number from Bluteooth SIG defined characteristic
ServiceConfigs.hardwareinfo = {
    id: 'hardwareinfo',
    service: '0000180a-0000-1000-8000-00805f9b34fb',
    config: 'hardware_revision_string',
    data: 'NA',
};
// used to read manufacturer name from Bluteooth SIG defined characteristic
ServiceConfigs.manufacturerinfo = {
    id: 'manufacturerinfo',
    service: '0000180a-0000-1000-8000-00805f9b34fb',
    config: 'manufacturer_name_string',
    data: 'NA',
};
