export const environment = {
  production: true,
  imageBucket: 'pocketlab-user-uploads',
  websocketAPIGatewayUrl:
    'wss://pj4gbymvy6.execute-api.us-east-1.amazonaws.com/ProdStage',
  entryAPIGatewayUrl: 'https://app.thepocketlab.com/api/entry',
  host: 'https://app.thepocketlab.com',
  entryAPIGatewayName: 'EntryGatewayProd',
  directEntryAPIGatewayUrl: 'https://app.thepocketlab.com/api/direct-entry',
  directEntryAPIGatewayName: 'DirectEntryGatewayProd',
  errorReportAPIEndpointUrl: 'https://app.thepocketlab.com/api/error',
  identityPoolId: 'us-east-1:f354e156-5543-4559-9786-6eae5b36cba3',
  userPoolId: 'us-east-1_PW0QaFILD',
  userPoolWebClientId: '4b8n51df3c414tcum1c45npe1i',
  oAuthDomain: 'notebook-prod.auth.us-east-1.amazoncognito.com',
  oneRosterAPIGatewayName: 'OneRosterGatewayProd',
  oneRosterAPIGatewayUrl: 'https://app.thepocketlab.com/api/oneroster',
  opensearchAPIGatewayName: 'OpensearchGatewayProd',
  opensearchAPIGatewayUrl: 'https://app.thepocketlab.com/api/opensearch',
  paymentAPIGatewayName: 'PaymentGatewayProd',
  paymentAPIGatewayUrl: 'https://app.thepocketlab.com/api/payment',
  userStatsAPIGatewayName: 'UserStatsGatewayProd',
  userStatsAPIGatewayUrl: 'https://app.thepocketlab.com/api/stats',
  authAPIGatewayName: 'AuthGatewayProd',
  authAPIGatewayUrl: 'https://auth.thepocketlab.com',
  posthogApiKey: 'phc_H9Yf3IFyIxNlr42YLOjlocfH4AYVitAZLrvdzL6fiPx',
};
