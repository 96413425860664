// Sensor Driver Handler class
export class SensorDriverHandler {
    hex(str) {
        let hexCodes = [];
        for (let i = 0; i < str.length; i++) {
            hexCodes.push(str.charCodeAt(i));
        }
        return hexCodes;
    }
}
