import {Injectable} from '@angular/core';

import {
  APIMessages as APIM,
  PL2,
  UtilsPL2 as U,
  KeyUtilsPL2 as KU,
} from '@common/utils/dist/index.js';

import {EntryStore} from '@stores/entry.store';
import {EntryStoreUtils as ESU} from '@utils/entry-store-utils';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {NEVER, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {WSMessageReceiverService} from '@services/ws-message-receiver.service';

@Injectable()
export class EntryMessageProcessor implements WebsocketMessageProcessor {
  constructor(
    private entryStore: EntryStore,
    private wsMessageReceiverService: WSMessageReceiverService,
  ) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const message = params.msg;
    if (message.int === APIM.RequestIntent.AppNotify) {
      return this.wsMessageReceiverService
        .receive<APIM.NotifyRequest>(message)
        .pipe(
          map((msg: APIM.NotifyRequest) => {
            if (!Array.isArray(msg.c) || msg.c.length === 0) {
              return of(true);
            }
            const pK = KU.keyFromEntry(msg.c[0] as PL2.AnyEntry);
            if (!U.isEmpty(message.e)) {
              return params.wsAPI
                .query({...pK, single: !KU.isTLE(msg.c[0]), idx: 'master'})
                .pipe(map(() => void 0));
            }

            const formattedEntries = msg.c
              .filter((e) => ESU.isKnownEntry(e, this.entryStore.state()))
              .map((e) => ESU.formatEntry(e));
            this.entryStore.modifyState(
              formattedEntries as PL2.AnyPartialEntryWithId[],
            );
            return of(void 0);
          }),
          map(() => true),
        );
    }
    return NEVER;
  }
}
