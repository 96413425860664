import { SensorType } from './service.config.js';
import { ProcessorStatus, MessageType, } from './connection-types.js';
import { ConnectionStrategyUtils as CSU } from './connection-strategy-utils.js';
import { DigitalInterfaceSensors } from './odyssey-digital-interface.js';
export class PacketProcessorStepFactory {
    build(stepSensors) {
        switch (stepSensors[0]?.type) {
            case SensorType.Encoder:
                return new EncoderPacketProcessorStep(stepSensors);
            case SensorType.Digital:
                return new DigitalPacketProcessorStep(stepSensors);
            default:
                return new NonEncoderPacketProcessorStep(stepSensors);
        }
    }
}
export class EncoderPacketProcessorStep {
    constructor(sensors) {
        this.sensorType = SensorType.Encoder;
        this._enconderTimePacketByteLength = 4;
        this._float32Configs = new Array();
        sensors.forEach((sensor) => this._float32Configs.push({ sensorId: sensor.id, isFirst: true }));
    }
    execute(dataView, ctx) {
        if (dataView.byteLength === 0) {
            return ctx;
        }
        const offset = dataView.getFloat32(0, true);
        this._float32Configs.forEach((config, i) => {
            const processedValue = dataView.getFloat32(this._enconderTimePacketByteLength + i * 4, true);
            ctx.sensorVals[config.sensorId].push([offset, processedValue]);
        });
        return ctx;
    }
    initPacketVals(ctx) {
        this._float32Configs.forEach((config) => {
            ctx.sensorVals[config.sensorId] = ctx.sensorVals[config.sensorId] ?? [];
        });
    }
    numNonEncoderSampleBytes() {
        return 0;
    }
    numEncoderSampleBytes() {
        if (this._float32Configs.length === 0) {
            return 0;
        }
        return this._enconderTimePacketByteLength + this._float32Configs.length * 4;
    }
}
export class NonEncoderPacketProcessorStep {
    constructor(sensors) {
        this.sensorType = SensorType.Standard;
        this._float32Configs = new Array();
        const flatConfig32Reg = new Map();
        let bigReadingFloatShift = 0;
        sensors.forEach((sensor) => {
            const bits = this._getBits(sensor.configValue);
            bits.forEach((bit, i) => {
                const numFloats = sensor.valueLength[i] / 4;
                for (let j = 0; j < numFloats; j++) {
                    const withBigReadingShift = bigReadingFloatShift + j;
                    if (flatConfig32Reg.get(bit << withBigReadingShift) === undefined) {
                        flatConfig32Reg.set(bit << withBigReadingShift, []);
                    }
                    flatConfig32Reg
                        .get(bit << withBigReadingShift)
                        .push({ sensorId: sensor.id, isFirst: i === 0 && j === 0 });
                }
                if (numFloats > 1) {
                    bigReadingFloatShift = bigReadingFloatShift + (numFloats - 1);
                }
            });
        });
        this._float32Configs = Array.from(flatConfig32Reg.values());
    }
    execute(dataView, ctx) {
        if (this._float32Configs.length === 0) {
            return ctx;
        }
        this._float32Configs.forEach((configs, i) => {
            configs.forEach((config) => {
                ctx.sensorVals[config.sensorId] = ctx.sensorVals[config.sensorId] ?? [];
                if (config.isFirst) {
                    ctx.sensorVals[config.sensorId].push([ctx.time]);
                }
                const sensorVal = dataView.getFloat32(i * 4, true);
                ctx.sensorVals[config.sensorId][ctx.sensorVals[config.sensorId].length - 1].push(sensorVal);
            });
        });
        return ctx;
    }
    _getBits(configVal) {
        var b = 1;
        var res = [];
        while (b <= configVal) {
            if (b & configVal)
                res.push(b);
            b <<= 1;
        }
        return res;
    }
    numNonEncoderSampleBytes() {
        return this._float32Configs.length * 4;
    }
    numEncoderSampleBytes() {
        return 0;
    }
}
export class DigitalPacketProcessorStep {
    constructor(sensors) {
        this.sensors = sensors;
        // Additional header bytes to skip for digital sensors
        this._digitalHeaderByteLength = 1;
    }
    execute(dataView, ctx) {
        if (this.sensors.length === 0) {
            return ctx;
        }
        // Process each sensor
        let i = 0;
        this.sensors.forEach((sensor) => {
            // Initialize sensor values array if not exists
            ctx.sensorVals[sensor.id] = ctx.sensorVals[sensor.id] ?? [];
            // For multiple bytes, collect them into a string
            let asciiString = [];
            for (let j = this._digitalHeaderByteLength; j < sensor.valueLength[0]; j++) {
                asciiString.push(dataView.getUint8(i + j));
            }
            const value = DigitalInterfaceSensors[sensor.secondaryConfig.disIdx].handler.processData(asciiString);
            ctx.sensorVals[sensor.id].push([ctx.time, ...value.map((v) => v[1])]);
            i = i + sensor.valueLength[0];
        });
        return ctx;
    }
    numNonEncoderSampleBytes() {
        // Each sample has the header bytes plus the sum of all sensor byte lengths
        return this.sensors.reduce((sum, sensor) => sum + sensor.valueLength[0], 0);
    }
    numSampleBytes() {
        return this.numNonEncoderSampleBytes();
    }
    numEncoderSampleBytes() {
        return 0;
    }
}
export class PowerlabDataPacketPipeline {
    constructor(deviceConfig) {
        this.deviceConfig = deviceConfig;
        this._packetConfigByteLength = 4;
        this._packetTypeByteLength = 1;
        this._numEncoderSamplesByteLength = 1;
        this._defaultEncoderTimeInterval = 0.02;
        this._nonSampleByteLength = this._packetTypeByteLength +
            this._packetConfigByteLength +
            this._numEncoderSamplesByteLength;
        // default encoder interval is so we have a valid frequency in the front end and isn't used in the point time calculation
        this._currentPacketConfig = 0;
        this._timeInterval = 0;
        this.nonEncoderSteps = new Array();
    }
    process(dataView, chunkInterval) {
        // can send empty packets during configuration
        console.log(CSU.toHex(dataView));
        if (dataView.byteLength === 0) {
            return [];
        }
        let index = this._packetTypeByteLength;
        let ctx = {
            sensorVals: {},
            chunkInterval: chunkInterval,
            time: 0,
            numEncoderSamples: 0,
        };
        let remainingBytes = dataView.byteLength - index;
        if (remainingBytes < this._packetConfigByteLength) {
            console.log('[PowerlabDataPacketPipeline] Erroneous packet: ', CSU.toHex(dataView));
            return [];
        }
        const newPacketConfig = dataView.getUint32(index, true);
        index = index + this._packetConfigByteLength;
        if (remainingBytes < this._numEncoderSamplesByteLength) {
            console.log('[PowerlabDataPacketPipeline] Erroneous packet: ', CSU.toHex(dataView));
            return [];
        }
        ctx.numEncoderSamples = dataView.getUint8(index);
        index = index + this._numEncoderSamplesByteLength;
        if (newPacketConfig !== this._currentPacketConfig) {
            this._initSteps(newPacketConfig);
            this._timeInterval = this._calculateNonEncoderTimeInterval(dataView, ctx);
        }
        try {
            // If there are no values we need to return an empty array to ensure
            // the graph
            this.encoderStep?.initPacketVals(ctx);
            const lastEncoderByteIdx = this._nonSampleByteLength + this._encoderPacketByteCount(ctx);
            const encoderSampleByteCount = this.encoderStep?.numEncoderSampleBytes() ?? 0;
            while (encoderSampleByteCount > 0 && index < lastEncoderByteIdx) {
                const stepDataView = new DataView(dataView.buffer.slice(index, index + encoderSampleByteCount));
                this.encoderStep?.execute(stepDataView, ctx);
                index = index + encoderSampleByteCount;
            }
            let packetNum = 0;
            while (this.nonEncoderSteps.length > 0 && index < dataView.byteLength) {
                this.nonEncoderSteps.forEach((step) => {
                    ctx.time = this._timeInterval * packetNum;
                    const stepDataView = new DataView(dataView.buffer.slice(index, index + step.numNonEncoderSampleBytes()));
                    step.execute(stepDataView, ctx);
                    index = index + step.numNonEncoderSampleBytes();
                });
                packetNum++;
            }
        }
        catch (e) {
            console.log(e);
            console.log('[PowerlabDataPacketPipeline] Erroneous packet: ', CSU.toHex(dataView));
            return [];
        }
        this._currentPacketConfig = newPacketConfig;
        return Object.keys(ctx.sensorVals).map((sensorId) => ({
            sensorId: sensorId,
            values: ctx.sensorVals[sensorId],
            frequency: 1 / this._timeInterval,
            type: MessageType.Reading,
            status: ProcessorStatus.SensorDataProcessing,
            chunkInterval: chunkInterval,
        }));
    }
    _initSteps(packetConfig) {
        this.nonEncoderSteps = [];
        this.encoderStep = null;
        const sensors = this._getSensorsInPacketConfig(packetConfig);
        // build encoder step
        if (sensors.encoder.length > 0) {
            this.encoderStep = new EncoderPacketProcessorStep(sensors.encoder);
        }
        // build non encoder steps (non-encoder readings can be repeated)
        let nonEncoderSensorIdx = 0;
        while (sensors.nonEncoder.length > 0 &&
            nonEncoderSensorIdx < sensors.nonEncoder.length) {
            const idxOfNextType = sensors.nonEncoder.findIndex((sensor, i) => i > nonEncoderSensorIdx &&
                sensor.type !== sensors.nonEncoder[nonEncoderSensorIdx].type);
            const stepEndIdx = idxOfNextType === -1 ? sensors.nonEncoder.length : idxOfNextType;
            const stepSensors = sensors.nonEncoder.slice(nonEncoderSensorIdx, stepEndIdx);
            const nonEncoderStep = new PacketProcessorStepFactory().build(stepSensors);
            this.nonEncoderSteps.push(nonEncoderStep);
            nonEncoderSensorIdx = stepEndIdx;
        }
    }
    _calculateNonEncoderTimeInterval(dataView, ctx) {
        const remainingBytes = dataView.byteLength - this._nonSampleByteLength;
        const encoderPacketByteCount = this._encoderPacketByteCount(ctx);
        const nonEncoderSampleByteCount = this._nonEncoderSampleBytesCount();
        return this._nonEncoderSampleBytesCount() > 0
            ? ctx.chunkInterval /
                ((remainingBytes - encoderPacketByteCount) /
                    nonEncoderSampleByteCount)
            : this._defaultEncoderTimeInterval;
    }
    _encoderPacketByteCount(ctx) {
        return !!this.encoderStep
            ? this.encoderStep.numEncoderSampleBytes() * ctx.numEncoderSamples
            : 0;
    }
    _nonEncoderSampleBytesCount() {
        return this.nonEncoderSteps.reduce((acc, step) => acc + step.numNonEncoderSampleBytes(), 0);
    }
    _getSensorsInPacketConfig(newPacketConfig) {
        return Object.values(this.deviceConfig.sensorConfigs)
            .filter((sensor) => (newPacketConfig & sensor.configValue) === sensor.configValue)
            .sort((a, b) => (a.configValue > b.configValue ? 1 : -1))
            .reduce((acc, sensor) => {
            if (sensor.type === SensorType.Encoder) {
                acc.encoder.push(sensor);
            }
            else {
                acc.nonEncoder.push(sensor);
            }
            return acc;
        }, { encoder: [], nonEncoder: [] });
    }
}
