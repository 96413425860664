import {Injectable} from '@angular/core';

import {UtilsPL2 as U} from '@common/utils/dist/index.js';
import {NEVER, Observable, of} from 'rxjs';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';

@Injectable()
export class VoidMessageProcessor implements WebsocketMessageProcessor {
  constructor() {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params?.msg;
    if (U.isEmpty(msg)) {
      return of(true);
    }
    return NEVER;
  }
}
