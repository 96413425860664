import { CommProtocol, DigitalPowerMode, I2CClockSpeed, } from './connection-types.js';
import * as OdysseyCommandCodes from './odyssey-command-codes.js';
import * as PLCommandCodes from './pl-command-codes.js';
import { SensorDriverHandler } from './sensor-driver-handler.js';
// registers
export const WHO_AM_I = 0x01;
export const READ_CAL_CHNL_DATA = 0x20;
export const READ_RAW_CHNL_DATA = 0x21;
// data lengths
export const CAL_CHNL_DATA_SIZE = 38; // 1 register byte, 1 byte to indicate first or second half of data, then 36 bytes of data (9 floats)
export const RAW_CHNL_DATA_SIZE = 37; // 1 register byte then 36 bytes (18 uint16_t) of data
export const WHO_AM_I_SIZE = 2; // 1 register byte then 1 byte of data (the address)
export const MIN_I2C_POLLING_INTERVAL = 100;
export const MAX_12C_POLLING_INTERVAL = 65535;
const validRegisters = new Set([
    WHO_AM_I,
    READ_CAL_CHNL_DATA,
    READ_RAW_CHNL_DATA,
]);
// spectroscopy sensor return codes
export var SpectroscopyRetcode;
(function (SpectroscopyRetcode) {
    SpectroscopyRetcode[SpectroscopyRetcode["SPECTROSCOPY_SUCCESS"] = 0] = "SPECTROSCOPY_SUCCESS";
    SpectroscopyRetcode[SpectroscopyRetcode["SPECTROSCOPY_ERR_DATA_EMPTY"] = 1] = "SPECTROSCOPY_ERR_DATA_EMPTY";
    SpectroscopyRetcode[SpectroscopyRetcode["SPECTROSCOPY_ERR_UNRECOGNIZED_CMD"] = 2] = "SPECTROSCOPY_ERR_UNRECOGNIZED_CMD";
})(SpectroscopyRetcode || (SpectroscopyRetcode = {}));
export class SpectroscopyDriver extends SensorDriverHandler {
    constructor() {
        super();
        this.err_code = SpectroscopyRetcode.SPECTROSCOPY_SUCCESS;
    }
    async spectroscopyTX(tx, size) {
        if (this.commandQueue == null) {
            throw new Error('spectroscopyTX - connection object is null');
        }
        if (tx == null || size < 1) {
            throw new Error('spectroscopyTX - invalid command');
        }
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_WRITE,
            this.interfaceID,
            size,
            ...tx,
        ]);
    }
    // regAddr - data to read
    // size - number of bytes to read
    async spectroscopyReadData(regAddr, size) {
        if (this.commandQueue == null) {
            throw new Error('spectroscopyReadData - connection object is null');
        }
        if (regAddr == null || !validRegisters.has(regAddr)) {
            throw new Error('spectroscopyReadData - invalid register address');
        }
        // write the register to read
        await this.spectroscopyTX([regAddr], 1);
        // await new Promise(f => setTimeout(f, 2000));
        // send i2c read command
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY,
            this.interfaceID,
            size,
        ]);
        // await new Promise(f => setTimeout(f, 2000));
        // send READ_DIGITAL_INTERFACE_X cmd
        if (this.interfaceID == 1) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_1 << 1) + 1,
            ]);
        }
        else if (this.interfaceID == 2) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_2 << 1) + 1,
            ]);
        }
        else {
            throw new Error('spectroscopyReadData - invalid interface ID');
        }
        // read back and process response
        await this.commandQueue.addRead().then((result) => {
            this.data = [];
            for (let i = 2; i < result.byteLength; i++) {
                this.data.push(result.getUint8(i));
            }
            this.processData(this.data);
        });
    }
    async init(commandQueue, port, customParams) {
        this.commandQueue = commandQueue;
        this.interfaceID = port;
        this.cal_chnl_data = [];
        this.raw_chnl_data = [];
        this.err_code = SpectroscopyRetcode.SPECTROSCOPY_SUCCESS;
        // clear rx and tx buffers
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.CLEAR_BUFFER,
            this.interfaceID,
        ]);
        this.err_code = await this.disable_polling(); // tell Odyssey to stop polling if it already is
        // read the WHO AM I register
        await this.spectroscopyReadData(WHO_AM_I, WHO_AM_I_SIZE);
        // set polling register to read
        await this.spectroscopyReadData(READ_CAL_CHNL_DATA, CAL_CHNL_DATA_SIZE); // send a read command of the desired data
        // enable polling
        this.err_code = await this.set_polling_interval(500);
        this.err_code = await this.set_polling_mode(OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY);
        this.err_code = await this.enable_polling();
        if (this.err_code == SpectroscopyRetcode.SPECTROSCOPY_SUCCESS) {
            return true;
        }
        else {
            await this.disable_polling();
            return false;
        }
    }
    // need to disable polling when clicking the disconnect button
    async disconnect() {
        await this.disable_polling();
    }
    async enable_polling() {
        if (this.i2c_polling_interval == null ||
            this.i2c_polling_interval == 0 ||
            this.i2c_polling_mode == null) {
            throw new Error('spectroscopy enable_polling - polling interval and mode arent set so cannot enable polling');
        }
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.ON,
        ]);
        return this.err_code;
    }
    async disable_polling() {
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.OFF,
        ]);
        return this.err_code;
    }
    // interval (uint16_t) is in ms
    async set_polling_interval(interval) {
        if (interval < MIN_I2C_POLLING_INTERVAL ||
            interval > MAX_12C_POLLING_INTERVAL) {
            throw new Error('spectroscopy set_polling_interval - invalid i2c polling interval');
        }
        this.i2c_polling_interval = interval;
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL,
            this.interfaceID,
            this.i2c_polling_interval & 0xff,
            (this.i2c_polling_interval >> 8) & 0xff,
        ]);
        return this.err_code;
    }
    // mode is the given operation to poll
    async set_polling_mode(mode) {
        if (mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_READ ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_WRITE ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE) {
            throw new Error('spectroscopy set_polling_mode - invalid i2c polling mode');
        }
        this.i2c_polling_mode = mode; // type is defined so any call to this that gives a nonexistent command code doesn't compile
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE,
            this.interfaceID,
            this.i2c_polling_mode,
        ]);
        return this.err_code;
    }
    processData(data = null) {
        if (data != null) {
            this.data = [];
            this.data = data;
        }
        else {
            return [];
        }
        if (this.data.length == 0) {
            this.err_code = SpectroscopyRetcode.SPECTROSCOPY_ERR_DATA_EMPTY;
            console.error(`HeartRate data empty, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        let uint8Arr = new Uint8Array(this.data);
        let buffer = uint8Arr.buffer;
        let dataView = new DataView(buffer);
        let i = 0;
        let exitLoop = false;
        while (!exitLoop && i < this.data.length) {
            switch (this.data[i]) {
                case READ_CAL_CHNL_DATA:
                    i++;
                    if (this.data[i] == 1) {
                        i++;
                        for (let j = 0; j < 9; j++) {
                            this.cal_chnl_data[j] = dataView.getFloat32(i, true);
                            i += 4;
                        }
                    }
                    else if (this.data[i] == 2) {
                        i++;
                        for (let j = 9; j < 18; j++) {
                            this.cal_chnl_data[j] = dataView.getFloat32(i, true);
                            i += 4;
                        }
                    }
                    this.data = []; // reset data
                    let x = 0;
                    return [
                        // return calibrated values
                        ['calibrated a', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated b', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated c', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated d', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated e', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated f', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated g', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated h', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated r', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated i', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated s', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated j', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated t', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated u', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated v', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated w', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated k', this.cal_chnl_data[x++], 'µW/cm²'],
                        ['calibrated l', this.cal_chnl_data[x++], 'µW/cm²'],
                    ];
                case READ_RAW_CHNL_DATA:
                    i++;
                    for (let j = 0; j < 18; j++) {
                        this.raw_chnl_data[j] = dataView.getUint16(i, true);
                        i += 2;
                    }
                    this.data = []; // reset data
                    let y = 0;
                    return [
                        // return raw adc values
                        ['raw a', this.raw_chnl_data[y++], 'counts'],
                        ['raw b', this.raw_chnl_data[y++], 'counts'],
                        ['raw c', this.raw_chnl_data[y++], 'counts'],
                        ['raw d', this.raw_chnl_data[y++], 'counts'],
                        ['raw e', this.raw_chnl_data[y++], 'counts'],
                        ['raw f', this.raw_chnl_data[y++], 'counts'],
                        ['raw g', this.raw_chnl_data[y++], 'counts'],
                        ['raw h', this.raw_chnl_data[y++], 'counts'],
                        ['raw r', this.raw_chnl_data[y++], 'counts'],
                        ['raw i', this.raw_chnl_data[y++], 'counts'],
                        ['raw s', this.raw_chnl_data[y++], 'counts'],
                        ['raw j', this.raw_chnl_data[y++], 'counts'],
                        ['raw t', this.raw_chnl_data[y++], 'counts'],
                        ['raw u', this.raw_chnl_data[y++], 'counts'],
                        ['raw v', this.raw_chnl_data[y++], 'counts'],
                        ['raw w', this.raw_chnl_data[y++], 'counts'],
                        ['raw k', this.raw_chnl_data[y++], 'counts'],
                        ['raw l', this.raw_chnl_data[y++], 'counts'],
                    ];
                case WHO_AM_I:
                    i++;
                    let addr = dataView.getUint8(i);
                    if (addr != Spectroscopy.commConfig.address) {
                        console.error(`WHO AM I register gave wrong I2C address: ${addr}`);
                    }
                    exitLoop = true;
                    break;
                default:
                    // Handle any other unrecognized command
                    this.err_code = SpectroscopyRetcode.SPECTROSCOPY_ERR_UNRECOGNIZED_CMD;
                    i++;
                    break;
            }
        }
        // reset data
        this.data = [];
        // return values
        return [];
    }
}
export const Spectroscopy = {
    name: 'Spectroscopy Sensor',
    pwrEn: true,
    pwrMode: DigitalPowerMode.DIGITAL_RAIL_3V3,
    comm: CommProtocol.I2C_External,
    ioEnable: true,
    commConfig: {
        address: 0x7a,
        baudRate: I2CClockSpeed.I2C_CLK_400K,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: new SpectroscopyDriver(),
};
