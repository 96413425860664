import {NgModule} from '@angular/core';

import {
  OverlayContainer,
  FullscreenOverlayContainer,
} from '@angular/cdk/overlay';
import {APP_BASE_HREF} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, RouteReuseStrategy} from '@angular/router';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {MatIconModule} from '@angular/material/icon';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';

import {AppRoutingModule} from './app-routing.module';
import {AppRouteReuseStrategy} from './app-route-reuse-strategy';

import {AppComponent} from './app.component';

import {DebugConsoleModule} from '@components/debug-console/debug-console.module';

import {APIService} from '@services/api.service';
import {BluetoothConnectionService} from '@services/bluetooth-connection.service';
import {CacheExpirationStrategyService} from '@services/cache-expiration-strategy.service';
import {CSVSerializerService} from '@services/csv-serializer.service';
import {DefaultCacheExpirationStrategyService} from '@services/default-cache-expiration-strategy.service';
import {ErrorReportApiService} from '@services/error-report-api.service';
import {FileService} from '@services/file.service';
import {HttpService} from '@services/http.service';
import {S3Service} from '@services/s3.service';
import {ServiceCommsWrapperService} from '@services/service-comms-wrapper.service';
import {StorageWrapperService} from '@services/storage-wrapper.service';

import {APIStateStore} from '@stores/api-state.store';
import {ErrorReportStore} from '@stores/error-report.store';
import {GeolocationStore} from '@stores/geolocation.store';
import {MissingTransitionStrategy} from '@stores/entry-store-automaton/modification-strategy';

import {ExpireEntriesAction} from '@actions/expire-entries.action';
import {DispatcherModule} from '@services/websocket-message-processor/dispatcher.module';
import {webSocket} from 'rxjs/webSocket';
import {ActAsTeacherPolicyService} from './policies/act-as-teacher-policy.service';
import {PolicyModule} from './directives/policy/policy.module';
import {VideoTutorialModule} from './components/video-tutorial/video-tutorial.module';
import {LoaderStore} from '@stores/loader.store';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    // necessary because there is a dialog in the ConnectionDataConfigStore
    MatDialogModule,
    DispatcherModule,
    DebugConsoleModule,
    PolicyModule,
    VideoTutorialModule,
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer},
    {provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy},
    {provide: 'WINDOW', useFactory: () => window},
    {provide: 'DOCUMENT', useFactory: () => document},
    {provide: 'SCREEN', useFactory: () => screen},
    {provide: 'STORAGE', useFactory: () => localStorage},
    {provide: 'SESSIONSTORAGE', useFactory: () => sessionStorage},
    APIService,
    BluetoothConnectionService,
    CSVSerializerService,
    ErrorReportApiService,
    FileService,
    HttpService,
    S3Service,
    ServiceCommsWrapperService,
    StorageWrapperService,
    APIStateStore,
    ErrorReportStore,
    GeolocationStore,
    ExpireEntriesAction,
    {
      provide: DefaultCacheExpirationStrategyService,
      useFactory: () =>
        new DefaultCacheExpirationStrategyService({
          maxErrors: 5,
          maxAge: 7889400,
          maxEntries: 10000,
        }),
      deps: [],
    },
    {
      provide: CacheExpirationStrategyService,
      useExisting: DefaultCacheExpirationStrategyService,
    },
    {
      provide: 'missingTransitionBuilder',
      useFactory: (errorReportStore) => (i, c) =>
        new MissingTransitionStrategy(c, i, errorReportStore),
      deps: [ErrorReportStore],
    },
    {provide: 'webSocketFactory', useValue: webSocket},
    {
      provide: 'actAsTeacher',
      useClass: ActAsTeacherPolicyService,
    },
    {provide: 'BUCKET', useValue: 'pocketlab-user-uploads'},
    {provide: 'MAX_WS_MESSAGE_SIZE', useValue: 127000},
    LoaderStore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {} // Don't inject anything else here as it slows down the bootstrapping and things aren't ready in time
}
