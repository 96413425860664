import {Injectable} from '@angular/core';
import {NEVER, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UtilsPL2 as U, APIMessages as APIM} from '@common/utils/dist/index.js';
import {EntryStore} from '@stores/entry.store';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {PendingOperation} from '@cloudlab/stores/entry-store-automaton/operation-state-machine';
import {APIError} from '@cloudlab/utils/api-errors';
import {WSMessageReceiverService} from '@services/ws-message-receiver.service';

@Injectable()
export class ErrorMessageProcessor implements WebsocketMessageProcessor {
  constructor(
    private entryStore: EntryStore,
    private wsMessageReceiverService: WSMessageReceiverService,
  ) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params.msg;
    if (msg.int === APIM.RequestIntent.AppError) {
      return this.wsMessageReceiverService
        .receive<APIM.NotifyRequest>(msg)
        .pipe(
          map((request) => {
            // const metadata: PL2.MessageMetadataEntry = request.c[0];
            const error = APIError.buildError({
              message: request.err?.message,
              status: request.err?.code,
            });
            const entries = request.c.filter(
              (e) => !(U.isEmpty(e.mId) || U.isEmpty(e.sK)),
            );
            /*
             * TODO handle error using the state machine or the component.
             * Just move failed entries to Ignored state
             */
            if (error.status !== 404) {
              this.entryStore.advanceCachedEntryState(
                entries,
                PendingOperation.Ignore,
                error,
              );
            }

            if (!U.isEmpty(params.wsAPI?.error)) {
              params.wsAPI.error(msg);
            }
            return true;
          }),
        );
    }
    return NEVER;
  }
}
