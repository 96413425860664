import { updateFullOdysseySystemConfig, } from './odyssey-system-config.js';
import { ExplorIR } from './explorir-driver.js';
import { LuminOx } from './luminox-driver.js';
import { HeartRate } from './heart-rate-driver.js';
import { SoilMoisture } from './soil-moisture-driver.js';
import { AirVelocity } from './air-velocity-driver.js';
import { Spectroscopy } from './spectroscopy-driver.js';
import { ExtSensorRapidProto } from './ext-sensor-rapid-proto-driver.js';
export async function editDigitalInterface(sensor, digitalConfig) {
    if (sensor == null) {
        throw new Error('configureDigitalInterface - sensor is null');
    }
    if (digitalConfig == null) {
        throw new Error('configureDigitalInterface - digitalConfig is null');
    }
    // set pwr_en
    let val = sensor.pwrEn ? 1 : 0;
    val <<= digitalConfig.pwrEnable.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.pwrEnable.mask);
    digitalConfig.value |= BigInt(val);
    // set pwr_mode
    val = sensor.pwrMode;
    val <<= digitalConfig.pwrMode.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.pwrMode.mask);
    digitalConfig.value |= BigInt(val);
    // set io_cfg
    val = sensor.comm;
    val <<= digitalConfig.ioConfig.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.ioConfig.mask);
    digitalConfig.value |= BigInt(val);
    // set io_en
    val = sensor.ioEnable ? 1 : 0;
    val <<= digitalConfig.ioEnable.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.ioEnable.mask);
    digitalConfig.value |= BigInt(val);
    // set address
    val = sensor.commConfig.address;
    val <<= digitalConfig.address.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.address.mask);
    digitalConfig.value |= BigInt(val);
    // set baudrate
    val = sensor.commConfig.baudRate;
    val <<= digitalConfig.baudRate.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.baudRate.mask);
    digitalConfig.value |= BigInt(val);
    // set pwm_freq
    val = sensor.commConfig.pwmFreq;
    val <<= digitalConfig.pwmFreq.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.pwmFreq.mask);
    digitalConfig.value |= BigInt(val);
    // set pwm_dc
    val = sensor.commConfig.pwmDC;
    val <<= digitalConfig.pwmDC.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.pwmDC.mask);
    digitalConfig.value |= BigInt(val);
    // set gpio
    val = sensor.commConfig.gpio;
    val <<= digitalConfig.gpio.offset;
    digitalConfig.value &= ~BigInt(digitalConfig.gpio.mask);
    digitalConfig.value |= BigInt(val);
    // update digital 1 byte array
    for (let i = 0; i < digitalConfig.byteArray.length; i++) {
        digitalConfig.byteArray[i] = Number((digitalConfig.value >> BigInt(i * 8)) & BigInt(0xff));
    }
}
// configureDigitalInterface()
// params:
// interfaceID - digital interface 1 or 2
// sensor - the sensor that's plugged into the given digital interface port
// sysConfig - system config object for the connected Odyssey device
// conn - connection object
//
// Configures the given digital interface port for the given sensor by updating the odyssey system config
export async function configureDigitalInterface(interfaceID, sensor, sysConfig, commandQueue) {
    if (commandQueue == null) {
        throw new Error('configureDigitalInterface - command queue object is null');
    }
    if (sensor == null) {
        throw new Error('configureDigitalInterface - sensor is null');
    }
    if (sysConfig == null) {
        throw new Error('configureDigitalInterface - sysConfig is null');
    }
    if (interfaceID == 1) {
        editDigitalInterface(sensor, sysConfig.DIG1);
    }
    else if (interfaceID == 2) {
        editDigitalInterface(sensor, sysConfig.DIG2);
    }
    else {
        throw new Error(`${interfaceID} is an invalid digital interface ID`);
    }
    // write updated odyssey system config to device
    await updateFullOdysseySystemConfig(sysConfig, commandQueue);
}
// place holder sensor
export const NoSensor = {
    name: 'None',
    pwrEn: false,
    pwrMode: 0,
    comm: 0,
    ioEnable: false,
    commConfig: {
        address: 0x0000,
        baudRate: 0x0000,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: null,
};
export const DigitalInterfaceSensors = [
    NoSensor,
    ExplorIR,
    LuminOx,
    HeartRate,
    SoilMoisture,
    AirVelocity,
    Spectroscopy,
    ExtSensorRapidProto,
];
