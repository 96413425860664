import { CommProtocol, DigitalPowerMode, I2CClockSpeed, } from './connection-types.js';
import * as OdysseyCommandCodes from './odyssey-command-codes.js';
import * as PLCommandCodes from './pl-command-codes.js';
import { SensorDriverHandler } from './sensor-driver-handler.js';
// data lengths
export const FLOW_DATA_SIZE = 5;
export const MIN_I2C_POLLING_INTERVAL = 100;
export const MAX_12C_POLLING_INTERVAL = 65535;
export const MAX_FLOW_DATA = 3686; // min and max of the flow range
export const MIN_FLOW_DATA = 409;
export const FS3000_1005 = 1005;
export const FS3000_1015 = 1015;
export const FS3000_1005_MAX_VELOCITY = 7.23; // fs3000-1005 is calibrated to measure from 0.0 to 7.23 m/sec
export const FS3000_1015_MAX_VELOCITY = 15.0; // fs3000-1015 is calibrated to measure from 0.0 to 15.0 m/sec
// air velocity sensor return codes
export var AirVelocityRetcode;
(function (AirVelocityRetcode) {
    AirVelocityRetcode[AirVelocityRetcode["AIR_VELOCITY_SUCCESS"] = 0] = "AIR_VELOCITY_SUCCESS";
    AirVelocityRetcode[AirVelocityRetcode["AIR_VELOCITY_ERR_DATA_EMPTY"] = 1] = "AIR_VELOCITY_ERR_DATA_EMPTY";
    AirVelocityRetcode[AirVelocityRetcode["AIR_VELOCITY_ERR_UNRECOGNIZED_CMD"] = 2] = "AIR_VELOCITY_ERR_UNRECOGNIZED_CMD";
    AirVelocityRetcode[AirVelocityRetcode["AIR_VELOCITY_ERR_INVALID_VER_NUM"] = 3] = "AIR_VELOCITY_ERR_INVALID_VER_NUM";
})(AirVelocityRetcode || (AirVelocityRetcode = {}));
export class AirVelocityDriver extends SensorDriverHandler {
    constructor() {
        super();
        this.err_code = AirVelocityRetcode.AIR_VELOCITY_SUCCESS;
    }
    // regAddr - data to read
    // size - number of bytes to read
    async airVelocityReadData(size) {
        if (this.commandQueue == null) {
            throw new Error('airVelocityReadData - connection object is null');
        }
        // send i2c read command
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY,
            this.interfaceID,
            size,
        ]);
        // send READ_DIGITAL_INTERFACE_X cmd
        if (this.interfaceID == 1) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_1 << 1) + 1,
            ]);
        }
        else if (this.interfaceID == 2) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_2 << 1) + 1,
            ]);
        }
        else {
            throw new Error('airVelocityReadData - invalid interface ID');
        }
        // read back and process response
        await this.commandQueue.addRead().then((result) => {
            this.data = [];
            for (let i = 2; i < result.byteLength; i++) {
                this.data.push(result.getUint8(i));
            }
            this.processData();
        });
    }
    async init(commandQueue, port, customParams) {
        this.commandQueue = commandQueue;
        this.interfaceID = port;
        this.version_num = customParams.version_num;
        if (this.version_num != FS3000_1005 && this.version_num != FS3000_1015) {
            this.err_code = AirVelocityRetcode.AIR_VELOCITY_ERR_INVALID_VER_NUM;
            return false;
        }
        this.err_code = AirVelocityRetcode.AIR_VELOCITY_SUCCESS;
        // clear rx and tx buffers
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.CLEAR_BUFFER,
            this.interfaceID,
        ]);
        this.err_code = await this.disable_polling();
        this.err_code = await this.set_polling_interval(125);
        this.err_code = await this.set_polling_mode(OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY);
        await this.airVelocityReadData(FLOW_DATA_SIZE); // send a read command of the desired data
        this.err_code = await this.enable_polling();
        if (this.err_code == AirVelocityRetcode.AIR_VELOCITY_SUCCESS) {
            return true;
        }
        else {
            return false;
        }
    }
    // need to disable polling when clicking the disconnect button
    async disconnect() {
        await this.disable_polling();
    }
    async enable_polling() {
        if (this.i2c_polling_interval == null ||
            this.i2c_polling_interval == 0 ||
            this.i2c_polling_mode == null) {
            throw new Error('air velocity enable_polling - polling interval and mode arent set so cannot enable polling');
        }
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.ON,
        ]);
        return this.err_code;
    }
    async disable_polling() {
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.OFF,
        ]);
        return this.err_code;
    }
    // interval (uint16_t) is in ms
    async set_polling_interval(interval) {
        if (interval < MIN_I2C_POLLING_INTERVAL ||
            interval > MAX_12C_POLLING_INTERVAL) {
            throw new Error('air velocity set_polling_interval - invalid i2c polling interval');
        }
        this.i2c_polling_interval = interval;
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL,
            this.interfaceID,
            this.i2c_polling_interval & 0xff,
            (this.i2c_polling_interval >> 8) & 0xff,
        ]);
        return this.err_code;
    }
    // mode is the given operation to poll
    async set_polling_mode(mode) {
        if (mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_READ ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_WRITE ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE) {
            throw new Error('air velocity set_polling_mode - invalid i2c polling mode');
        }
        this.i2c_polling_mode = mode; // type is defined so any call to this that gives a nonexistent command code doesn't compile
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE,
            this.interfaceID,
            this.i2c_polling_mode,
        ]);
        return this.err_code;
    }
    fs3000_checksum(data) {
        if (data.length != 5) {
            throw new Error('air velocity fs3000_checksum - invalid data length');
        }
        let checksum = data[0] & 0xff;
        let sum = (data[1] & 0xff) + (data[2] & 0xff) + (data[3] & 0xff) + (data[4] & 0xff);
        if (((checksum + sum) & 0xff) === 0) {
            this.checksum_status = true;
        }
        else {
            this.checksum_status = false;
        }
    }
    calculate_air_velocity() {
        if (this.flow_data <= MIN_FLOW_DATA) {
            this.air_velocity = 0.0;
            return;
        }
        else if (this.flow_data >= MAX_FLOW_DATA) {
            // I'm seeing flow data values above 3686, got the max code of 4095, but I'm leaving this here because that means the reading is out of the ADC's calibrated range, so we can't trust the velocity calculated using it.
            if (this.version_num == FS3000_1005)
                this.air_velocity = 7.23;
            if (this.version_num == FS3000_1015)
                this.air_velocity = 15.0;
            return;
        }
        // calculate air velocity using polynomial trend-line equation (calculated using data from datasheet)
        if (this.version_num == FS3000_1005) {
            this.air_velocity =
                1e-10 * Math.pow(this.flow_data, 3) -
                    4e-7 * Math.pow(this.flow_data, 2) +
                    0.0022 * this.flow_data -
                    0.7196;
        }
        else if (this.version_num == FS3000_1015) {
            this.air_velocity =
                4e-10 * Math.pow(this.flow_data, 3) -
                    1e-6 * Math.pow(this.flow_data, 2) +
                    0.004 * this.flow_data -
                    1.4903;
        }
        return;
    }
    processData(data = null) {
        if (data != null) {
            this.data = [];
            this.data = data;
        }
        else {
            return [];
        }
        if (this.data.length == 0) {
            this.err_code = AirVelocityRetcode.AIR_VELOCITY_ERR_DATA_EMPTY;
            console.error(`AirVelocity data empty, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        else if (this.data.length != 5) {
            this.err_code = AirVelocityRetcode.AIR_VELOCITY_ERR_UNRECOGNIZED_CMD;
            console.error(`AirVelocity data length does not match, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        this.fs3000_checksum(data);
        // don't update air velocity if the output failed the checksum
        if (this.checksum_status) {
            this.flow_data = ((data[1] & 0x0f) << 8) | data[2];
            this.calculate_air_velocity();
        }
        // reset data
        this.data = [];
        // return values
        return [['Air velocity', this.air_velocity, 'm/sec']];
    }
}
export const AirVelocity = {
    name: 'Air Velocity Sensor',
    pwrEn: true,
    pwrMode: DigitalPowerMode.DIGITAL_RAIL_3V3,
    comm: CommProtocol.I2C_External,
    ioEnable: true,
    commConfig: {
        address: 0x28,
        baudRate: I2CClockSpeed.I2C_CLK_100K,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: new AirVelocityDriver(),
};
