import { CommProtocol, DigitalPowerMode, I2CClockSpeed, } from './connection-types.js';
import * as OdysseyCommandCodes from './odyssey-command-codes.js';
import * as PLCommandCodes from './pl-command-codes.js';
import { SensorDriverHandler } from './sensor-driver-handler.js';
// data lengths
export const ADC_CONV_SIZE = 2;
export const MIN_I2C_POLLING_INTERVAL = 100;
export const MAX_12C_POLLING_INTERVAL = 65535;
// soil moisture sensor return codes
export var SoilMoistureRetcode;
(function (SoilMoistureRetcode) {
    SoilMoistureRetcode[SoilMoistureRetcode["SOIL_MOISTURE_SUCCESS"] = 0] = "SOIL_MOISTURE_SUCCESS";
    SoilMoistureRetcode[SoilMoistureRetcode["SOIL_MOISTURE_ERR_DATA_EMPTY"] = 1] = "SOIL_MOISTURE_ERR_DATA_EMPTY";
    SoilMoistureRetcode[SoilMoistureRetcode["SOIL_MOISTURE_ERR_UNRECOGNIZED_CMD"] = 2] = "SOIL_MOISTURE_ERR_UNRECOGNIZED_CMD";
})(SoilMoistureRetcode || (SoilMoistureRetcode = {}));
export class SoilMoistureDriver extends SensorDriverHandler {
    constructor() {
        super();
        this.err_code = SoilMoistureRetcode.SOIL_MOISTURE_SUCCESS;
    }
    // regAddr - data to read
    // size - number of bytes to read
    async soilMoistureReadData(size) {
        if (this.commandQueue == null) {
            throw new Error('soilMoistureReadData - connection object is null');
        }
        // send i2c read command
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY,
            this.interfaceID,
            size,
        ]);
        // send READ_DIGITAL_INTERFACE_X cmd
        if (this.interfaceID == 1) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_1 << 1) + 1,
            ]);
        }
        else if (this.interfaceID == 2) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_2 << 1) + 1,
            ]);
        }
        else {
            throw new Error('soilMoistureReadData - invalid interface ID');
        }
        // read back and process response
        await this.commandQueue.addRead().then((result) => {
            this.data = [];
            for (let i = 2; i < result.byteLength; i++) {
                this.data.push(result.getUint8(i));
            }
            this.processData();
        });
    }
    async init(commandQueue, port, customParams) {
        this.commandQueue = commandQueue;
        this.interfaceID = port;
        this.moisture_lvl = 0.0;
        this.calibrate_to_soil(2, 3260); // default dry and wet values
        this.err_code = SoilMoistureRetcode.SOIL_MOISTURE_SUCCESS;
        // clear rx and tx buffers
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.CLEAR_BUFFER,
            this.interfaceID,
        ]);
        this.err_code = await this.disable_polling();
        this.err_code = await this.set_polling_interval(1000);
        this.err_code = await this.set_polling_mode(OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY);
        await this.soilMoistureReadData(ADC_CONV_SIZE); // send a read command of the desired data
        this.err_code = await this.enable_polling();
        if (this.err_code == SoilMoistureRetcode.SOIL_MOISTURE_SUCCESS) {
            return true;
        }
        else {
            return false;
        }
    }
    // need to disable polling when clicking the disconnect button
    async disconnect() {
        await this.disable_polling();
    }
    async enable_polling() {
        if (this.i2c_polling_interval == null ||
            this.i2c_polling_interval == 0 ||
            this.i2c_polling_mode == null) {
            throw new Error('soil moisture enable_polling - polling interval and mode arent set so cannot enable polling');
        }
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.ON,
        ]);
        return this.err_code;
    }
    async disable_polling() {
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.OFF,
        ]);
        return this.err_code;
    }
    // interval (uint16_t) is in ms
    async set_polling_interval(interval) {
        if (interval < MIN_I2C_POLLING_INTERVAL ||
            interval > MAX_12C_POLLING_INTERVAL) {
            throw new Error('soil moisture set_polling_interval - invalid i2c polling interval');
        }
        this.i2c_polling_interval = interval;
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL,
            this.interfaceID,
            this.i2c_polling_interval & 0xff,
            (this.i2c_polling_interval >> 8) & 0xff,
        ]);
        return this.err_code;
    }
    // mode is the given operation to poll
    async set_polling_mode(mode) {
        if (mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_READ ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_WRITE ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE) {
            throw new Error('soil moisture set_polling_mode - invalid i2c polling mode');
        }
        this.i2c_polling_mode = mode; // type is defined so any call to this that gives a nonexistent command code doesn't compile
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE,
            this.interfaceID,
            this.i2c_polling_mode,
        ]);
        return this.err_code;
    }
    calibrate_to_soil(dry_value, wet_value) {
        this.adc_max = wet_value;
        this.adc_min = dry_value;
    }
    processData(data = null) {
        if (data != null) {
            this.data = [];
            this.data = data;
        }
        else {
            return [];
        }
        if (this.data.length == 0) {
            this.err_code = SoilMoistureRetcode.SOIL_MOISTURE_ERR_DATA_EMPTY;
            console.error(`SoilMoisture data empty, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        else if (this.data.length != 2) {
            this.err_code = SoilMoistureRetcode.SOIL_MOISTURE_ERR_UNRECOGNIZED_CMD;
            console.error(`SoilMoisture data length does not match, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        this.conv_result = ((data[0] & 0x0f) << 8) | data[1];
        if (this.conv_result <= this.adc_min) {
            this.moisture_lvl = 0;
        }
        else if (this.conv_result >= this.adc_max) {
            this.moisture_lvl = 100;
        }
        else {
            this.moisture_lvl =
                ((this.conv_result - this.adc_min) / (this.adc_max - this.adc_min)) *
                    100;
        }
        // reset data
        this.data = [];
        // return values
        return [['Moisture Level', this.moisture_lvl, '%']];
    }
}
export const SoilMoisture = {
    name: 'Soil Moisture Sensor',
    pwrEn: true,
    pwrMode: DigitalPowerMode.DIGITAL_RAIL_3V3,
    comm: CommProtocol.I2C_External,
    ioEnable: true,
    commConfig: {
        address: 0x4c,
        baudRate: I2CClockSpeed.I2C_CLK_100K,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: new SoilMoistureDriver(),
};
