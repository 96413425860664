import { SensorType } from './service.config.js';
export var ConnectionStrategyUtils;
(function (ConnectionStrategyUtils) {
    function chunkInterval(frequencyInstruction) {
        return ((frequencyInstruction >> 16) & 0xffff) / 1000;
    }
    ConnectionStrategyUtils.chunkInterval = chunkInterval;
    function sampleInterval(frequencyInstruction) {
        return (chunkInterval(frequencyInstruction) / (frequencyInstruction & 0xffff));
    }
    ConnectionStrategyUtils.sampleInterval = sampleInterval;
    function numPackets(frequencyInstruction) {
        return frequencyInstruction & 0xff;
    }
    ConnectionStrategyUtils.numPackets = numPackets;
    function sortByNewSensors(newInits, previous) {
        const newSensors = newInits.filter((nI) => !previous?.some((pI) => pI.sensorId === nI.sensorId));
        return [
            ...newInits.sort((a, b) => +newSensors.some((s) => s.sensorId === b.sensorId) -
                +newSensors.some((s) => s.sensorId === a.sensorId)),
        ];
    }
    ConnectionStrategyUtils.sortByNewSensors = sortByNewSensors;
    function isTooManyBytesInPacket(inits, fC, dC) {
        const numBytesInTimeValue = 4;
        const totalBytesInPacket = inits
            .filter((i) => !i.failed)
            .map((i) => i.sensorId)
            .reduce((acc, sId) => {
            const sC = dC.sensorConfigs[sId];
            // if it is an encoder sensor then include the time packet
            return (acc +
                sC.valueLength.reduce(function (a, b) {
                    return a + b;
                }, 0) +
                (sC.type === SensorType.Encoder ? numBytesInTimeValue : 0));
        }, 0);
        const numPacketsInChunk = numPackets(fC.i);
        return totalBytesInPacket > dC.maxNumSensorBytesInChunk / numPacketsInChunk;
    }
    ConnectionStrategyUtils.isTooManyBytesInPacket = isTooManyBytesInPacket;
    function toHex(dataView) {
        let hex = '';
        for (let i = 0; i < dataView.byteLength; i++) {
            hex += dataView.getUint8(i).toString(16).padStart(2, '0');
            hex += ' ';
        }
        return hex;
    }
    ConnectionStrategyUtils.toHex = toHex;
    function isNotCombinedSensor(sensor) {
        return (sensor.configValue & (sensor.configValue - 1)) === 0;
    }
    ConnectionStrategyUtils.isNotCombinedSensor = isNotCombinedSensor;
})(ConnectionStrategyUtils || (ConnectionStrategyUtils = {}));
