import {UnitConfigs, UnitConfig} from './unit.config';
import {Color} from './app.config';
import {
  Altitude,
  Zero,
  ZeroOneTwoValues,
  ThreeFourFiveValues,
  SixSevenEightValues,
  FirstValue,
  SecondValue,
  ThirdValue,
  EuclideanNorm,
  EuclideanNormAcc,
  DewPoint,
  HeatIndex,
  Raf,
  VoyThr,
  VoyTp,
  TenMinuteAverage,
  Aqi,
  Switch,
  Abs,
  PascalsToMBar,
  ZeroOneValues,
  VoltageToTactilePressure,
  AmpsToMilliAmps,
} from '@constants/point-transformer-pipeline';

export enum AxesVisibility {
  Multiple = 0,
  Single = 1,
  All = 2,
}

export enum Visualization {
  Line = 0,
  Bar = 1,
  Digits = 2,
  Map = 3,
  Button = 4,
  Speedometer = 5,
  Orientation = 6,
}

export const ProgressBarColors: Array<string> = ['primary', 'accent', 'warn'];

export interface GraphConfig {
  id: string;
  name: string;
  commonName: string;
  minRange: Array<number>;
  labels: Array<string>;
  unitConfigs: Array<UnitConfig>;
  transform?: any[];
  autoZero?: boolean;
  yLabel?: string;
  xLabel?: string;
  minValues?: Array<number>;
  maxValues?: Array<number>;
  significantChange: Array<number>;
  buttonLabels: Array<string>;
  order?: number;
  axesVisibility: AxesVisibility;
  defaultAxis?: number; // use only for 3rd party data
  visualizations: Array<Visualization>;
  pointsPerPage?: number;
  useCurrentLocation?: boolean;
  axesModulus?: number;
  colors: Array<Color>;
  decimalPlaces: Array<number>;
  hide?: boolean;
  thirdPartyData?: boolean;
  underDevelopment?: boolean;
  disabled?: boolean;
  separateCsv?: boolean;
  configValue?: number;
}

const alt: GraphConfig = {
  id: 'altitude',
  name: 'pressureAltitudeName',
  commonName: 'Altitude',
  unitConfigs: [UnitConfigs.len],
  labels: ['pressureAltitudeLabel0'],
  transform: [Altitude, Zero],
  minRange: [10, 30],
  minValues: [-5300, -15900],
  maxValues: [9500, 28500],
  significantChange: [1280],
  buttonLabels: [null],
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  axesVisibility: AxesVisibility.Multiple,
  useCurrentLocation: true,
  colors: [Color.Red],
  decimalPlaces: [1],
};

const pre: GraphConfig = {
  id: 'pre',
  name: 'pressureBarometricName',
  commonName: 'Pressure',
  unitConfigs: [UnitConfigs.pressure],
  labels: ['pressureBarometricLabel0'],
  minRange: [10, 1],
  minValues: [0, 0],
  maxValues: [1100, 110],
  significantChange: [110],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  useCurrentLocation: true,
  colors: [Color.Red],
  decimalPlaces: [2],
  // valueIndex: 0,
  transform: [FirstValue],
};

const lig: GraphConfig = {
  id: 'lig',
  name: 'lightIntensityName',
  commonName: 'Light Intensity',
  minRange: [100],
  unitConfigs: [UnitConfigs.lightIntesity],
  labels: ['lightIntensityLabel0'],
  minValues: [0],
  maxValues: [100000],
  significantChange: [10000],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  useCurrentLocation: true,
  colors: [Color.Red],
  decimalPlaces: [0],
};

const tem: GraphConfig = {
  id: 'tem',
  name: 'internalTemperatureName',
  commonName: 'Temperature',
  minValues: [-20, -4, 253],
  maxValues: [80, 176, 353],
  significantChange: [10],
  buttonLabels: [null],
  labels: ['internalTemperatureName'],
  unitConfigs: [UnitConfigs.temperature],
  minRange: [2, 5, 2],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  useCurrentLocation: true,
  colors: [Color.Red],
  decimalPlaces: [1],
  transform: [SecondValue],
  // valueIndex: 1,
};

const hum: GraphConfig = {
  id: 'hum',
  name: 'humidityName',
  commonName: 'Humidity',
  minRange: [5],
  unitConfigs: [UnitConfigs.humidity],
  labels: ['Humidity'],
  minValues: [0],
  maxValues: [100],
  significantChange: [10],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  useCurrentLocation: true,
  colors: [Color.Red],
  decimalPlaces: [1],
  transform: [ThirdValue],
};

const dew: GraphConfig = {
  id: 'dew',
  name: 'dewPointName',
  commonName: 'Dew Point',
  minRange: [2, 5],
  transform: [DewPoint],
  unitConfigs: [UnitConfigs.temperature],
  labels: ['dewPointLabel0'],
  minValues: [-20, -4],
  maxValues: [80, 176],
  significantChange: [10],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  useCurrentLocation: true,
  colors: [Color.Red],
  decimalPlaces: [1],
};

const hea: GraphConfig = {
  id: 'hea',
  commonName: 'Heat Index',
  name: 'heatIndexName',
  minRange: [2, 5],
  transform: [HeatIndex],
  minValues: [-20, -4],
  maxValues: [80, 176],
  significantChange: [10],
  buttonLabels: [null],
  unitConfigs: [UnitConfigs.temperature],
  labels: ['heatIndexLabel0'],
  axesVisibility: AxesVisibility.Multiple,
  useCurrentLocation: true,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  colors: [Color.Red],
  decimalPlaces: [1],
};

const acc8: GraphConfig = {
  id: 'acc8',
  commonName: 'Acceleration',
  name: 'accelerationBName',
  minRange: [1, 10],
  order: 0,
  labels: ['accelerationBLabel0', 'accelerationBLabel1', 'accelerationBLabel2'],
  minValues: [-8, -80],
  maxValues: [8, 80],
  significantChange: [1.6, 1.6, 1.6],
  buttonLabels: [
    'accelerationBButton0',
    'accelerationBButton1',
    'accelerationBButton2',
  ],
  unitConfigs: [
    UnitConfigs.acceleration,
    UnitConfigs.acceleration,
    UnitConfigs.acceleration,
  ],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red, Color.Blue, Color.Green],
  decimalPlaces: [2, 2, 2],
};

const acc16: GraphConfig = {
  ...acc8,
  id: 'acc16',
  minValues: [-16, -160],
  maxValues: [16, 160],
  transform: [ZeroOneTwoValues],
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Speedometer,
  ],
};

const accSca: GraphConfig = {
  id: 'accSca',
  name: 'accelerationScalarName',
  commonName: 'Acceleration Scalar',
  order: 0,
  labels: ['accelerationScalarLabel0'],
  unitConfigs: [UnitConfigs.acceleration],
  transform: [EuclideanNormAcc],
  minRange: [1, 10],
  minValues: [-1, -10],
  maxValues: [28, -280],
  significantChange: [2.9],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Speedometer,
  ],
  colors: [Color.Red],
  decimalPlaces: [2],
};

const gyr: GraphConfig = {
  id: 'gyr',
  commonName: 'Angular Velocity',
  name: 'angularVelocityName',
  unitConfigs: [
    UnitConfigs.angularVelocity,
    UnitConfigs.angularVelocity,
    UnitConfigs.angularVelocity,
  ],
  minRange: [20, 3, 1],
  labels: [
    'angularVelocityLabel0',
    'angularVelocityLabel1',
    'angularVelocityLabel2',
  ],
  autoZero: true,
  transform: [Zero],
  minValues: [-2000, -300, -100],
  maxValues: [2000, 300, 100],
  significantChange: [400, 400, 400],
  buttonLabels: [
    'angularVelocityButton0',
    'angularVelocityButton1',
    'angularVelocityButton2',
  ],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red, Color.Blue, Color.Green],
  decimalPlaces: [2, 2, 2],
};

const mag: GraphConfig = {
  id: 'mag',
  name: 'magneticFieldName',
  commonName: 'Magnetic Field',
  minRange: [20],
  unitConfigs: [
    UnitConfigs.magneticField,
    UnitConfigs.magneticField,
    UnitConfigs.magneticField,
  ],
  labels: ['magneticFieldLabel0', 'magneticFieldLabel1', 'magneticFieldLabel2'],
  transform: [Zero],
  autoZero: false,
  minValues: [-4900],
  maxValues: [4900],
  significantChange: [980, 980, 980],
  buttonLabels: [
    'magneticFieldButton0',
    'magneticFieldButton1',
    'magneticFieldButton2',
  ],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red, Color.Blue, Color.Green],
  decimalPlaces: [2, 2, 2],
};

const magMag: GraphConfig = {
  id: 'magMag',
  name: 'magneticFieldMagnitudeName',
  commonName: 'Magnetic Field Magnitude',
  minRange: [20],
  labels: ['magneticFieldMagnitudeLabel0'],
  unitConfigs: [UnitConfigs.magneticField],
  transform: [Zero, EuclideanNorm],
  autoZero: false,
  minValues: [0],
  maxValues: [4900],
  significantChange: [980],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [2],
};

const weather: GraphConfig = {
  id: 'weather',
  name: 'measurementConditionsBName',
  commonName: 'Measurement Conditions',
  labels: [
    'measurementConditionsBLabel0',
    'measurementConditionsBLabel1',
    'measurementConditionsBLabel2',
    'measurementConditionsBLabel3',
  ],
  unitConfigs: [
    UnitConfigs.temperature,
    UnitConfigs.humidity,
    UnitConfigs.pressure,
    UnitConfigs.lightIntesity,
  ],
  order: 1,
  minRange: [-1, -1, -1, -1],
  significantChange: [10, 10, 110, 10000],
  buttonLabels: [
    'measurementConditionsBButton0',
    'measurementConditionsBButton1',
    'measurementConditionsBButton2',
    'measurementConditionsBButton3',
  ],
  axesVisibility: AxesVisibility.All,
  useCurrentLocation: true,
  visualizations: [
    Visualization.Digits,
    Visualization.Button,
    Visualization.Map,
  ],
  colors: [Color.Red, Color.Red, Color.Red, Color.Red],
  decimalPlaces: [1, 0, 1, 0],
  hide: true,
};

const ran: GraphConfig = {
  id: 'ran',
  name: 'rangefinderName',
  commonName: 'Position',
  minRange: [0.2, 0.6],
  unitConfigs: [UnitConfigs.len],
  labels: ['rangefinderLabel0'],
  minValues: [0, 0],
  maxValues: [2.3, 6.9],
  significantChange: [0.23],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [2],
  // valueIndex: 0,
  transform: [Raf, FirstValue],
};

const vel: GraphConfig = {
  id: 'vel',
  name: 'rangefinderVelocityName',
  commonName: 'Velocity',
  // transform: T.raf,
  minRange: [5, 30],

  unitConfigs: [UnitConfigs.velocity],
  labels: ['rangefinderVelocityLabel0'],
  minValues: [-10, -60],
  maxValues: [10, 60],
  significantChange: [2],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [2],
  transform: [Raf, SecondValue],
};

const plantower = {
  id: 'plantower',
  name: 'particulateMatterName',
  commonName: 'Particulate Matter',
  order: 0,
  minRange: [5, 5, 5, 5, 5, 5],
  unitConfigs: [
    UnitConfigs.particulateMatter,
    UnitConfigs.particulateMatter,
    UnitConfigs.particulateMatter,
    UnitConfigs.particulateMatter,
    UnitConfigs.particulateMatter,
    UnitConfigs.particulateMatter,
  ],
  labels: [
    'particulateMatterLabel0',
    'particulateMatterLabel1',
    'particulateMatterLabel2',
    'particulateMatterLabel3',
    'particulateMatterLabel4',
    'particulateMatterLabel5',
  ],
  yLabel: 'particulateMatterYLabel',
  minValues: [0, 0, 0, 0, 0, 0],
  pointsPerPage: 1800,
  maxValues: [500, 500, 500, 500, 500, 500],
  significantChange: [50, 50, 50, 50, 50, 50],
  buttonLabels: ['particulateMatterButton0', 'particulateMatterButton1'],
  visualizations: [Visualization.Line, Visualization.Map, Visualization.Button],
  axesVisibility: AxesVisibility.Single,
  useCurrentLocation: true,
  axesModulus: 3,
  colors: [Color.Red, Color.Red, Color.Red, Color.Blue, Color.Blue, Color.Blue],
  decimalPlaces: [1, 1, 1, 1, 1, 1],
  transform: [TenMinuteAverage],
};

const cozir = {
  id: 'cozir',
  name: 'carbonDioxideName',
  commonName: 'Carbon Dioxide',
  order: 2,
  pointsPerPage: 1800,
  minRange: [500],
  labels: ['carbonDioxideLabel0', 'carbonDioxideLabel1'],
  yLabel: 'carbonDioxideYLabel',
  unitConfigs: [UnitConfigs.carbonDioxide, UnitConfigs.carbonDioxide],
  minValues: [0],
  maxValues: [5000],
  significantChange: [500],
  buttonLabels: [null, 'carbonDioxideButton1'],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Map, Visualization.Button],
  useCurrentLocation: true,
  colors: [Color.Red, Color.Blue],
  decimalPlaces: [0, 0],
  transform: [TenMinuteAverage],
};

const ozone = {
  id: 'ozone',
  name: 'ozoneName',
  commonName: 'Ozone',
  pointsPerPage: 1800,
  order: 1,
  minRange: [5],
  labels: ['ozoneLabel0', 'ozoneLabel1'],
  yLabel: 'ozoneYLabel',
  unitConfigs: [UnitConfigs.ozone, UnitConfigs.ozone],
  minValues: [0, 0],
  maxValues: [500, 500],
  significantChange: [50, 50],
  buttonLabels: ['ozoneButton0'],
  axesVisibility: AxesVisibility.Single,
  visualizations: [Visualization.Line, Visualization.Map, Visualization.Button],
  useCurrentLocation: true,
  colors: [Color.Blue, Color.Red],
  decimalPlaces: [1, 1],
  transform: [TenMinuteAverage, Switch],
};

const aqi = {
  id: 'aqi',
  name: 'baseAirQualityIndexName',
  pointsPerPage: 1800,
  commonName: 'Air Quality Index',
  minRange: [50],
  order: 3,
  labels: ['baseAirQualityIndexLabel0', 'baseAirQualityIndexLabel1'],
  yLabel: 'baseAirQualityIndexYLabel',
  unitConfigs: [UnitConfigs.airQualityIndex, UnitConfigs.airQualityIndex],
  minValues: [0, 0],
  maxValues: [500, 500],
  significantChange: [50, 50],
  buttonLabels: [null, 'baseAirQualityIndexButton1'],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Map, Visualization.Button],
  useCurrentLocation: true,
  colors: [Color.Red, Color.Blue],
  decimalPlaces: [0, 0],
  transform: [Aqi, TenMinuteAverage],
};

const temPro: GraphConfig = {
  id: 'temPro',
  name: 'temperatureProbeBName',
  commonName: 'Temperature Probe',
  unitConfigs: [UnitConfigs.temperature],
  minRange: [2, 5, 2],
  labels: ['temperatureProbeBLabel0'],
  minValues: [-20, -4, 253],
  maxValues: [80, 176, 353],
  significantChange: [13],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  useCurrentLocation: true,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  colors: [Color.Red],
  decimalPlaces: [1, 1, 1],
  // transform: T.voyThr,
  transform: [VoyThr],
};

const adc: GraphConfig = {
  id: 'adc',
  name: 'adcName',
  commonName: 'ADC',
  axesVisibility: AxesVisibility.Single,
  minRange: [1000],
  unitConfigs: [UnitConfigs.adc],
  labels: ['adcLabel0'],
  minValues: [0],
  maxValues: [1024],
  significantChange: [102.4],
  buttonLabels: [null],
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [2],
  hide: false,
};

const tac: GraphConfig = {
  id: 'tac',
  name: 'tactilePressureName',
  commonName: 'Tactile Pressure',
  axesVisibility: AxesVisibility.Multiple,
  minRange: [100],
  unitConfigs: [UnitConfigs.arbitraryUnits],
  labels: ['tactilePressureLabel0'],
  minValues: [0],
  maxValues: [100],
  significantChange: [10],
  buttonLabels: [null],
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [1],
  transform: [VoyTp],
};

const mic: GraphConfig = {
  id: 'mic',
  name: 'audioName',
  commonName: 'Audio',
  minRange: [80],
  unitConfigs: [UnitConfigs.none],
  labels: ['audioLabel0'],
  minValues: [-10],
  maxValues: [100],
  significantChange: [11],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Speedometer,
  ],
  colors: [Color.Red],
  decimalPlaces: [2],
};

const the: GraphConfig = {
  id: 'the',
  name: 'temperatureProbeName',
  commonName: 'Temperature Probe',
  unitConfigs: [UnitConfigs.temperature, UnitConfigs.temperature],
  minRange: [5, 10, 5],
  labels: ['temperatureProbeLabel0', 'temperatureProbeLabel1'],
  minValues: [-20, -4, 253],
  maxValues: [110, 110],
  significantChange: [13, 13],
  buttonLabels: [null, null],
  axesVisibility: AxesVisibility.Multiple,
  useCurrentLocation: true,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Map,
  ],
  colors: [Color.Red, Color.Blue],
  decimalPlaces: [2, 2],
};

const encdis: GraphConfig = {
  id: 'encdis',
  name: 'displacementName',
  commonName: 'Displacement',
  minRange: [0.5, 1.5],
  transform: [Zero],
  unitConfigs: [UnitConfigs.len],
  labels: ['displacementLabel0'],
  minValues: [-1000, -3000],
  maxValues: [1000, 3000],
  significantChange: [0.23],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Single,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [3],
  separateCsv: true,
};

const encvel: GraphConfig = {
  id: 'encvel',
  name: 'velocityName',
  commonName: 'Velocity',
  minRange: [0.5, 1.5],
  unitConfigs: [UnitConfigs.velocity],
  labels: ['velocityLabel0'],
  minValues: [-10, -30],
  maxValues: [10, 30],
  significantChange: [0.23],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Single,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Speedometer,
  ],
  colors: [Color.Red],
  decimalPlaces: [3],
  separateCsv: true,
};

const encspe: GraphConfig = {
  id: 'encspe',
  name: 'speedName',
  commonName: 'Speed',
  minRange: [0.5, 1.5],
  unitConfigs: [UnitConfigs.velocity],
  labels: ['speedLabel0'],
  minValues: [0, 0],
  maxValues: [10, 30],
  significantChange: [0.23],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Single,
  visualizations: [
    Visualization.Speedometer,
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
  ],
  colors: [Color.Red],
  decimalPlaces: [3],
  transform: [Abs],
  order: 2,
  separateCsv: true,
};

const encacc: GraphConfig = {
  id: 'encacc',
  name: 'accelerationName',
  commonName: 'Acceleration',
  minRange: [1, 3],
  unitConfigs: [UnitConfigs.accelerationEnc],
  labels: ['accelerationLabel0'],
  minValues: [-160, -540],
  maxValues: [160, 540],
  significantChange: [0.23],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Single,
  visualizations: [
    Visualization.Line,
    Visualization.Bar,
    Visualization.Button,
    Visualization.Speedometer,
  ],
  colors: [Color.Red],
  decimalPlaces: [3],
  separateCsv: true,
};

const heading: GraphConfig = {
  id: 'imuhea',
  name: 'headingName',
  commonName: 'Heading',
  minRange: [40, 0.4],
  unitConfigs: [UnitConfigs.angle],
  labels: ['headingLabel'],
  minValues: [-180, -3.142],
  maxValues: [180, 3.142],
  significantChange: [20, 0.5],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Single,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [3],
  transform: [Zero],
};

const pitch: GraphConfig = {
  id: 'imupit',
  name: 'pitchName',
  commonName: 'Pitch',
  minRange: [40, 0.4],
  unitConfigs: [UnitConfigs.angle],
  labels: ['pitchLabel'],
  minValues: [-90, -1.571],
  maxValues: [90, 1.571],
  significantChange: [20, 0.5],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Single,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [3],
  transform: [Zero],
};

const roll: GraphConfig = {
  id: 'imurol',
  name: 'rollName',
  commonName: 'Roll',
  minRange: [40, 0.4],
  unitConfigs: [UnitConfigs.angle],
  labels: ['rollLabel'],
  minValues: [-180, -3.142],
  maxValues: [180, 3.142],
  significantChange: [20, 0.5],
  buttonLabels: [null],
  axesVisibility: AxesVisibility.Single,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [3],
  transform: [Zero],
};

const quaternion: GraphConfig = {
  id: 'imuqua',
  name: 'quaternionName',
  commonName: 'Quaternion',
  minRange: [40, 0.4],
  unitConfigs: [
    UnitConfigs.none,
    UnitConfigs.none,
    UnitConfigs.none,
    UnitConfigs.none,
  ],
  labels: ['', '', '', ''],
  minValues: [-180, -3.142],
  maxValues: [180, 3.142],
  significantChange: [20, 0.5],
  buttonLabels: ['Quat ', 'Quat ', 'Quat ', 'Quat '],
  axesVisibility: AxesVisibility.All,
  visualizations: [Visualization.Orientation, Visualization.Button],
  colors: [Color.Red, Color.Blue, Color.Green, Color.Brown],
  decimalPlaces: [2],
};

const odyph: GraphConfig = {
  id: 'odyph',
  name: 'phName',
  commonName: 'PH',
  minRange: [3],
  unitConfigs: [UnitConfigs.none],
  labels: ['phLabel0'],
  minValues: [0],
  maxValues: [14],
  significantChange: [0.23],
  buttonLabels: [''],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [2],
};

const odyorp: GraphConfig = {
  id: 'odyorp',
  name: 'orpName',
  commonName: 'ORP',
  minRange: [3],
  unitConfigs: [UnitConfigs.none],
  labels: [''],
  minValues: [0],
  maxValues: [14],
  significantChange: [0.23],
  buttonLabels: [''],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [3],
};

const odydisox: GraphConfig = {
  id: 'odydisox',
  name: 'dissolvedOxygenName',
  commonName: 'Dissolved Oxygen',
  minRange: [3],
  unitConfigs: [UnitConfigs.none],
  labels: ['dissolvedOxygenLabel0'],
  minValues: [0],
  maxValues: [14],
  significantChange: [0.23],
  buttonLabels: [''],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [3],
};

const odyvol: GraphConfig = {
  id: 'odyvol',
  name: 'voltageName',
  commonName: 'Voltage',
  minRange: [0.1],
  unitConfigs: [UnitConfigs.voltage],
  labels: [''],
  minValues: [-33],
  maxValues: [33],
  significantChange: [0.23],
  buttonLabels: [''],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [3],
};

const odycur: GraphConfig = {
  id: 'odycur',
  name: 'currentName',
  commonName: 'Current',
  minRange: [0.1],
  unitConfigs: [UnitConfigs.current],
  labels: [''],
  minValues: [2000],
  maxValues: [0],
  significantChange: [0.23],
  buttonLabels: [''],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  transform: [AmpsToMilliAmps],
  decimalPlaces: [2],
};

const odyres: GraphConfig = {
  id: 'odyres',
  name: 'resistanceName',
  commonName: 'Resistance',
  minRange: [20],
  unitConfigs: [UnitConfigs.resistance],
  labels: [''],
  minValues: [0],
  maxValues: [1100000],
  significantChange: [0.23],
  buttonLabels: [''],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [1],
};

const odyele: GraphConfig = {
  id: 'odyele',
  name: 'electricalConductivityName',
  commonName: 'Electrical Conductivity',
  minRange: [20],
  unitConfigs: [UnitConfigs.electricalConductivity],
  labels: [''],
  minValues: [0],
  maxValues: [110000],
  significantChange: [100],
  buttonLabels: [''],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
  decimalPlaces: [0],
};

const odyox: GraphConfig = {
  id: 'odyox',
  transform: [SecondValue],
  name: 'o2digitalName',
  disabled: true,
  decimalPlaces: [1],
  unitConfigs: [UnitConfigs.percent],
  minRange: [2],
  commonName: 'O2 Gas',
  labels: [''],
  minValues: [0],
  maxValues: [100],
  significantChange: [1],
  buttonLabels: [''],
  axesVisibility: AxesVisibility.Multiple,
  visualizations: [Visualization.Line, Visualization.Bar, Visualization.Button],
  colors: [Color.Red],
};

export const GraphConfigs: Array<GraphConfig> = [
  // Odyssey
  {
    ...odyph,
    configValue: 0x00000001,
    id: 'odyph',
  },
  {
    ...odyorp,
    id: 'odyorp',
    disabled: true,
    configValue: 0x00000002,
  },
  {
    ...odydisox,
    id: 'odydisox',
    disabled: true,
    configValue: 0x00000004,
  },
  {
    ...odyvol,
    id: 'odyvol1',
    configValue: 0x00000008,
  },
  {
    ...odycur,
    id: 'odycur1',
    configValue: 0x00000010,
  },
  {
    ...odyres,
    id: 'odyres1',
    configValue: 0x00000020,
  },
  {
    ...temPro,
    id: 'odypro1',
    transform: [],
    configValue: 0x00000040,
  },
  {
    ...odyvol,
    id: 'odyvol2',
    configValue: 0x00000080,
  },
  {
    ...odycur,
    id: 'odycur2',
    configValue: 0x00000100,
  },
  {
    ...odyres,
    id: 'odyres2',
    configValue: 0x00000200,
  },
  {
    ...temPro,
    id: 'odypro2',
    transform: [],
    configValue: 0x00000400,
  },
  {
    ...odyox,
    id: 'odyox1',
    disabled: true,
    configValue: 0x00000800,
  },
  {
    ...odyox,
    id: 'odyox2',
    disabled: true,
    configValue: 0x00001000,
  },
  {
    ...odyox,
    id: 'odyco1',
    disabled: true,
    name: 'co2digitalName',
    // configValue: 0x00001000,
  },
  {
    ...odyox,
    id: 'odyco2',
    disabled: true,
    name: 'co2digitalName',
    // configValue: 0x00008000,
  },
  {
    ...odyele,
    id: 'odyele',
    configValue: 0x00002000,
  },
  {
    ...odyele,
    id: 'odyele',
    configValue: 0x00004000,
  },
  {
    ...pre,
    id: 'odypre',
    decimalPlaces: [1],
    unitConfigs: [UnitConfigs.siPressure],
    configValue: 0x00004000,
    transform: [],
    maxValues: [440, 4400],
    minValues: [0, 0],
  },

  // Voyager 2 (powerlab)

  {
    ...heading,
    id: 'imuhea_dev',
    underDevelopment: true,
    configValue: 0x00000001,
  },
  {
    ...pitch,
    id: 'imupit_dev',
    underDevelopment: true,
    configValue: 0x00000002,
  },
  {
    ...roll,
    id: 'imurol_dev',
    underDevelopment: true,
    configValue: 0x00000004,
  },

  {
    ...acc16,
    id: 'imuacc_v2',
    name: 'accelerationName',
    configValue: 0x00000008,
  },
  {
    ...accSca,
    id: 'imuacc_sca_v2',
    configValue: 0x00000010,
  },
  {
    ...gyr,
    id: 'imugyr_v2',
    transform: [Zero],
    autoZero: false,
    configValue: 0x00000020,
  },

  // imumag and imumag_mag (magnetic field and magnetic field magnitudue were already defined
  // as part of the GForce configs and were foolishly reused as is without assigning a new id.
  // They have been added here to show a coherent configValue sequence
  // {...mag, id: 'imumag', transform: [Zero], configValue: 0x00000040},
  // {...magMag, id: 'imumag_mag', transform: [Zero, EuclideanNorm], configValue: 0x00000080},

  {
    ...adc,
    id: 'plvol',
    transform: [],
    underDevelopment: true,
    name: 'voltageName',
    configValue: 0x00000100,
  },
  {
    ...adc,
    id: 'plres',
    transform: [],
    underDevelopment: true,
    name: 'resistanceName',
    configValue: 0x00000200,
  },
  {
    ...adc,
    id: 'plcur',
    transform: [],
    underDevelopment: true,
    name: 'currentName',
    configValue: 0x00000400,
  },
  {
    ...temPro,
    id: 'plpro',
    transform: [],
    configValue: 0x00000800,
  },

  // imuqua (quaternion) was already defined as part of the GForce configs and was foolishly
  // reused as is without assigning a new id. It has been added as a comment here to show
  // a coherent configValue sequence
  // {...quaternion, configValue: 0x00001000},
  {
    ...tac,
    id: 'pltac',
    transform: [VoltageToTactilePressure],
    configValue: 0x00002000,
  },

  {
    ...tem,
    id: 'pltem',
    transform: [],
    configValue: 0x00004000,
  },
  {
    ...pre,
    id: 'plpre',
    transform: [PascalsToMBar],
    configValue: 0x00008000,
  },
  {
    ...hum,
    id: 'plhum',
    transform: [],
    configValue: 0x00010000,
  },
  {
    ...alt,
    id: 'plalt',
    transform: [Zero],
    configValue: 0x00020000,
  },
  {
    ...dew,
    id: 'pldew',
    transform: [],
    configValue: 0x00040000,
  },
  {
    ...hea,
    id: 'plhea',
    transform: [],
    configValue: 0x00080000,
  },
  {
    ...encdis,

    id: 'randst',
    name: 'rangefinderDistanceName',
    separateCsv: false,
    configValue: 0x00100000,
  },
  {
    ...encdis,
    id: 'randis',
    name: 'rangefinderDisplacementName',
    underDevelopment: true,
    separateCsv: false,
    configValue: 0x00200000,
  },
  {
    ...encvel,
    id: 'ranvel',
    name: 'rangefinderVelocityName',
    underDevelopment: true,
    separateCsv: false,
    configValue: 0x00400000,
  },
  {
    ...encacc,
    id: 'ranacc',
    name: 'rangefinderAccelerationName',
    underDevelopment: true,
    separateCsv: false,
    configValue: 0x00800000,
  },
  {
    ...lig,
    id: 'pluvl',
    name: 'uvLightIntensityName',
    transform: [],
    decimalPlaces: [2],
    underDevelopment: true,
    configValue: 0x01000000,
  },
  {
    ...lig,
    id: 'plaml',
    name: 'ambientLightIntensityName',
    transform: [],
    decimalPlaces: [2],
    configValue: 0x02000000,
  },
  {
    ...lig,
    id: 'plirl',
    name: 'irLightIntensityName',
    transform: [],
    decimalPlaces: [2],
    underDevelopment: true,
    configValue: 0x04000000,
  },

  // GForce (powerlab)
  {
    ...encdis,
    configValue: 0x00000001,
  },
  {
    ...encvel,
    configValue: 0x00000002,
  },
  {
    ...encspe,
    configValue: 0x00000004,
  },
  {
    ...encacc,
    configValue: 0x00000008,
  },
  {
    ...acc16,
    id: 'imuacc',
    name: 'accelerationXYZName',
    configValue: 0x00000010,
  },
  {
    ...accSca,
    id: 'imuacc_sca',
    configValue: 0x00000020,
  },
  {
    ...mag,
    id: 'imumag',
    transform: [Zero],
    configValue: 0x00000040,
  },
  {
    ...magMag,
    id: 'imumag_mag',
    transform: [Zero, EuclideanNorm],
    configValue: 0x00000080,
  },
  {
    ...gyr,
    id: 'imugyr',
    transform: [Zero],
    autoZero: false,
    configValue: 0x00000100,
  },
  {
    ...heading,
    configValue: 0x00000200,
  },
  {
    ...pitch,
    configValue: 0x00000400,
  },
  {
    ...roll,
    configValue: 0x00000800,
  },
  {
    ...quaternion,
    configValue: 0x00001000,
  },

  // PL One
  {...acc8, id: 'pl1acc-acc', separateCsv: true},
  {...accSca, id: 'pl1acc-acc_sca', separateCsv: true},
  {...gyr, id: 'pl1gyr-ang', separateCsv: true},
  {...mag, id: 'pl1mag-mag', separateCsv: true},
  {...magMag, id: 'pl1mag-mag_mag', separateCsv: true},
  {...alt, id: 'pl1bar-alt', useCurrentLocation: false, separateCsv: true},
  {...pre, id: 'pl1bar-pre', useCurrentLocation: false, separateCsv: true},
  {...tem, id: 'pl1bar-tem_int', useCurrentLocation: false, separateCsv: true},

  {...acc16, id: 'mpu9250-acc'},
  {...accSca, id: 'mpu9250-acc_sca'},
  {
    ...gyr,
    id: 'mpu9250-ang',
    transform: [ThreeFourFiveValues, ...gyr.transform],
  },
  {
    ...mag,
    id: 'mpu9250-mag',
    transform: [SixSevenEightValues, ...mag.transform],
    minValues: [-4800],
    maxValues: [4800],
  },
  {
    ...magMag,
    id: 'mpu9250-mag_mag',
    transform: [SixSevenEightValues, ...magMag.transform],
  },
  {...ran, id: 'vl53l0x-ran'},
  {...vel, id: 'vl53l0x-ran_vel'},
  {...alt, id: 'bme280-alt'},
  {...pre, id: 'bme280-pre'},
  {...lig, id: 'apds9301-lig'},
  {...tem, id: 'bme280-tem_int'},
  {...temPro, id: 'ntc10k-tem_pro'},
  {...hum, id: 'bme280-hum'},
  {...dew, id: 'bme280-dew'},
  {...hea, id: 'bme280-hea'},
  {...weather, id: 'bme280-weather'},
  {...adc, id: 'ntc10k-adc'},
  {...tac, id: 'ntc10k-tac'},

  // Onboard
  {...acc16, id: 'onboardacc-acc'},
  {...accSca, id: 'onboardacc-acc_sca'},
  {...gyr, id: 'onboardgyr-ang'},
  {...mag, id: 'onboardmag-mag'},
  {...magMag, id: 'onboardmag-mag_mag'},
  {...lig, id: 'onboardlig-lig'},
  {...mic, id: 'onboardmic-mic'},

  // Air
  {...plantower, id: 'air-plantower'},
  {...plantower, id: 'air-plantower0000'},
  {...plantower, id: 'air-plantower8e40'},
  {...plantower, id: 'air-plantowercdea'},
  {...cozir, id: 'air-cozir'},
  {...cozir, id: 'air-cozir0000'},
  {...cozir, id: 'air-cozir8e40'},
  {...cozir, id: 'air-cozircdea'},
  {...ozone, id: 'air-ozone'},
  {...ozone, id: 'air-ozone0000'},
  {...ozone, id: 'air-ozone8e40'},
  {...ozone, id: 'air-ozonecdea'},
  {...aqi, id: 'air-aqi'},
  {...aqi, id: 'air-aqi0000'},
  {...aqi, id: 'air-aqi8e40'},
  {...aqi, id: 'air-aqicdea'},
  {...pre, id: 'air-pre0000', pointsPerPage: 1800},
  {...lig, id: 'air-lig0000', pointsPerPage: 1800},
  {...tem, id: 'air-tem_int0000', pointsPerPage: 1800},
  {...hum, id: 'air-hum0000', pointsPerPage: 1800},
  {...dew, id: 'air-dew0000', pointsPerPage: 1800},
  {...hea, id: 'air-hea0000', pointsPerPage: 1800},
  {...alt, id: 'air-alt0000', pointsPerPage: 1800},
  {...weather, id: 'air-weather0000', order: 4},
  {...pre, id: 'air-pre8e40', pointsPerPage: 1800},
  {...lig, id: 'air-lig8e40', pointsPerPage: 1800},
  {...tem, id: 'air-tem_int8e40', pointsPerPage: 1800},
  {...hum, id: 'air-hum8e40', pointsPerPage: 1800},
  {...dew, id: 'air-dew8e40', pointsPerPage: 1800},
  {...hea, id: 'air-hea8e40', pointsPerPage: 1800},
  {...alt, id: 'air-alt8e40', pointsPerPage: 1800},
  {...weather, id: 'air-weather8e40', order: 4},
  {...pre, id: 'air-pre', pointsPerPage: 1800},
  {...lig, id: 'air-lig', pointsPerPage: 1800},
  {...tem, id: 'air-tem_int', pointsPerPage: 1800},
  {...hum, id: 'air-hum', pointsPerPage: 1800},
  {...dew, id: 'air-dew', pointsPerPage: 1800},
  {...hea, id: 'air-hea', pointsPerPage: 1800},
  {...alt, id: 'air-alt', pointsPerPage: 1800},
  {...weather, id: 'air-weather', order: 4},
  {...pre, id: 'air-precdea', pointsPerPage: 1800},
  {...lig, id: 'air-ligcdea', pointsPerPage: 1800},
  {...tem, id: 'air-tem_intcdea', pointsPerPage: 1800},
  {...hum, id: 'air-humcdea', pointsPerPage: 1800},
  {...dew, id: 'air-dewcdea', pointsPerPage: 1800},
  {...hea, id: 'air-heacdea', pointsPerPage: 1800},
  {...alt, id: 'air-altcdea', pointsPerPage: 1800},
  {...weather, id: 'air-weathercdea', order: 4},

  // PL4
  {...the, id: 'pl4-pl4tem_pro'},

  // PL4.2
  {...the, id: 'pl4-pl42tem_pro', colors: [Color.Blue, Color.Red]},

  // Thermo 2.1
  {
    ...the,
    id: 'tem_pro',
    colors: [Color.Red, Color.Blue],
    transform: [ZeroOneValues],
  },

  // Others
  {
    id: 'openweather-all',
    useCurrentLocation: true,
    name: 'measurementConditionsName',
    commonName: 'Measurement Conditions',
    labels: [
      'measurementConditionsLabel0',
      'measurementConditionsLabel1',
      'measurementConditionsLabel2',
    ],
    unitConfigs: [
      UnitConfigs.pressure,
      UnitConfigs.temperature,
      UnitConfigs.humidity,
    ],
    minRange: [-1],
    significantChange: [110, 10, 10],
    buttonLabels: [
      'measurementConditionsButton0',
      'measurementConditionsButton1',
      'measurementConditionsButton2',
    ],
    order: 2,
    axesVisibility: AxesVisibility.Single,
    defaultAxis: 1,
    visualizations: [Visualization.Map, Visualization.Button],
    colors: [Color.Red, Color.Blue, Color.Green],
    decimalPlaces: [1, 1, 0],
    thirdPartyData: true,
  },

  {
    id: 'aqicn',
    name: 'airQualityIndexName',
    commonName: 'Air Quality Index',
    labels: [
      'airQualityIndexLabel0',
      'airQualityIndexLabel1',
      'airQualityIndexLabel2',
      'airQualityIndexLabel3',
    ],
    yLabel: 'airQualityIndexYLabel',
    unitConfigs: [
      UnitConfigs.airQualityIndex,
      UnitConfigs.ozone,
      UnitConfigs.particulateMatter,
      UnitConfigs.particulateMatter,
    ],
    minRange: [50],
    significantChange: [50],
    buttonLabels: [null, null, null, null],
    order: 5,
    useCurrentLocation: true,
    axesVisibility: AxesVisibility.Single,
    visualizations: [Visualization.Map, Visualization.Button],
    colors: [Color.Red, Color.Red, Color.Red, Color.Red],
    decimalPlaces: [0, 0, 1, 1],
    thirdPartyData: true,
  },

  {
    id: 'imported-data',
    name: '',
    commonName: 'NA',
    minRange: [-1],
    unitConfigs: new Array(32).fill(UnitConfigs.none),
    labels: ['importedDataLabel0'],
    yLabel: 'importedDataYLabel',
    xLabel: 'importedDataXLabel',
    minValues: [],
    pointsPerPage: 0,
    maxValues: [],
    significantChange: [],
    buttonLabels: [null],
    visualizations: [
      Visualization.Line,
      Visualization.Bar,
      Visualization.Button,
    ],
    axesVisibility: AxesVisibility.All,
    axesModulus: 8,
    colors: [...new Array(32).keys()].map((i) => i % 8),
    decimalPlaces: new Array(32).fill(-1),
  },
];
