// Odyssey specific command codes
// Telemetry Commands
export const READ_PH = 0x50;
export const READ_ORP = 0x51;
export const READ_DISSOLVED_OXYGEN = 0x52;
export const READ_VOLTAGE_1 = 0x53;
export const READ_CURRENT_1 = 0x54;
export const READ_RESISTANCE_1 = 0x55;
export const READ_TEMPERATURE_1 = 0x56;
export const READ_VOLTAGE_2 = 0x57;
export const READ_CURRENT_2 = 0x58;
export const READ_RESISTANCE_2 = 0x59;
export const READ_TEMPERATURE_2 = 0x5a;
export const READ_DIGITAL_INTERFACE_1 = 0x5b;
export const READ_DIGITAL_INTERFACE_2 = 0x5c;
export const READ_ELECTRICAL_CONDUCTIVITY = 0x5d;
export const READ_ABS_PRESSURE = 0x5e;
// Control Commands
export const DIGITAL_INTERFACE_CTRL = 0x2e;
export var DIGITAL_INTERFACE_CMDS;
(function (DIGITAL_INTERFACE_CMDS) {
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["I2C_WRITE"] = 0] = "I2C_WRITE";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["I2C_WRITE_RS"] = 1] = "I2C_WRITE_RS";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["UART_WRITE"] = 2] = "UART_WRITE";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["I2C_READ"] = 128] = "I2C_READ";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["I2C_READ_ONLY"] = 144] = "I2C_READ_ONLY";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["UART_READ"] = 160] = "UART_READ";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["POLLING_EN"] = 16] = "POLLING_EN";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["POLLING_MODE"] = 17] = "POLLING_MODE";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["POLLING_INTERVAL"] = 18] = "POLLING_INTERVAL";
    DIGITAL_INTERFACE_CMDS[DIGITAL_INTERFACE_CMDS["CLEAR_BUFFER"] = 169] = "CLEAR_BUFFER";
})(DIGITAL_INTERFACE_CMDS || (DIGITAL_INTERFACE_CMDS = {}));
export const RUN_PROBE_CAL = 0x2d;
// probe cal codes
export const PH_CAL = 0x01;
export const DO_CAL = 0x02;
export const ORP_CAL = 0x03;
export const EC_CAL = 0x04;
export const RESET_CALIBRATION = 0x99;
// Configuration Commands
export const ODYSSEY_SYSTEM_CONFIG_KEY = 0xb9;
export const PH_ORP_DO_CONFIG = 0x00;
export const EC_CONFIG = 0x01;
export const VIRT_CONFIG = 0x02;
export const DIG_CONFIG = 0x03;
export const MAIN_PWR_CONFIG = 0x04;
