import { ValidatorFactory, } from './validator-core.js';
import { LenientValidator } from './lenient-validator.js';
import { BaseLabReportEntryValidatorFactory } from './base-lab-report-entry-validator-factory.js';
import { DashboardIdxEntryValidatorFactory } from './dashboard-idx-entry-validator-factory.js';
import { ClassIdxEntryValidatorFactory } from './class-idx-entry-validator-factory.js';
import { UserLibraryEntryIdxEntryValidatorFactory } from './user-library-entry-idx-entry-validator-factory.js';
import { SortableEntryValidatorFactory } from './sortable-entry-validator-factory.js';
import { EntryUtilsPL2 as EU } from '../utils/entry-utils-pl2.js';
import { ThemableValidatorFactory } from './themable-validator-factory.js';
export class AssignmentEntryValidatorFactory extends ValidatorFactory {
    constructor(builderFactory) {
        super(builderFactory);
        this.builderFactory = builderFactory;
    }
    getInstance(o) {
        const factory = {};
        this.loadFactory(factory, o, ...EU.assignmentEntryIdAtts);
        return factory;
    }
    getAttributeValidators(attr, value) {
        switch (attr) {
            case 'sCCt':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'uLG':
            case 'isC':
            case 'tmp':
            case 'aG':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            case 'shC':
            case 'lmsCId':
            case 'lmsAId':
            case 'lmsUId':
            case 'sMId':
            case 'sSK':
                return [new LenientValidator(value, this.builderFactory(attr))];
            case 'lmsUIds':
                return [
                    new LenientValidator(value, this.builderFactory(attr)),
                ];
            default:
                if (EU.baseLabReportEntryAtts.includes(attr)) {
                    return new BaseLabReportEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.classIdxEntryAtts.includes(attr)) {
                    return new ClassIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.userLibraryEntryIdxEntryAtts.includes(attr)) {
                    return new UserLibraryEntryIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.sortableEntryAtts.includes(attr)) {
                    return new SortableEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else if (EU.themableAtts.includes(attr)) {
                    return new ThemableValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
                else {
                    return new DashboardIdxEntryValidatorFactory(this.builderFactory).getAttributeValidators(attr, value);
                }
        }
    }
}
