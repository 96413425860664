import { CommProtocol, DigitalPowerMode, I2CClockSpeed, } from './connection-types.js';
import * as OdysseyCommandCodes from './odyssey-command-codes.js';
import * as PLCommandCodes from './pl-command-codes.js';
import { SensorDriverHandler } from './sensor-driver-handler.js';
// registers
export const WHO_AM_I = 0x01;
export const READ_HEARTRATE = 0x10;
export const READ_CONFIDENCE = 0x11;
export const READ_BLOOD_OX = 0x12;
export const READ_STATUS = 0x13;
export const READ_ALL = 0x14;
// data lengths
export const WHO_AM_I_SIZE = 2;
export const HEARTRATE_SIZE = 2;
export const CONFIDENCE_SIZE = 1;
export const BLOOD_OX_SIZE = 2;
export const STATUS_SIZE = 1;
export const ALL_SIZE = 7;
export const MIN_I2C_POLLING_INTERVAL = 100;
export const MAX_12C_POLLING_INTERVAL = 65535;
const validRegisters = new Set([
    WHO_AM_I,
    READ_HEARTRATE,
    READ_CONFIDENCE,
    READ_BLOOD_OX,
    READ_STATUS,
    READ_ALL,
]);
// heart rate sensor return codes
export var HeartRateRetcode;
(function (HeartRateRetcode) {
    HeartRateRetcode[HeartRateRetcode["HEARTRATE_SUCCESS"] = 0] = "HEARTRATE_SUCCESS";
    HeartRateRetcode[HeartRateRetcode["HEARTRATE_ERR_DATA_EMPTY"] = 1] = "HEARTRATE_ERR_DATA_EMPTY";
    HeartRateRetcode[HeartRateRetcode["HEARTRATE_ERR_UNRECOGNIZED_CMD"] = 2] = "HEARTRATE_ERR_UNRECOGNIZED_CMD";
})(HeartRateRetcode || (HeartRateRetcode = {}));
export class HeartRateDriver extends SensorDriverHandler {
    constructor() {
        super();
        this.err_code = HeartRateRetcode.HEARTRATE_SUCCESS;
    }
    async heartRateTX(tx, size) {
        if (this.commandQueue == null) {
            throw new Error('heartRateTX - connection object is null');
        }
        if (tx == null || size < 1) {
            throw new Error('heartRateTX - invalid command');
        }
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_WRITE,
            this.interfaceID,
            size,
            ...tx,
        ]);
    }
    // regAddr - data to read
    // size - number of bytes to read
    async heartRateReadData(regAddr, size) {
        if (this.commandQueue == null) {
            throw new Error('heartRateReadData - connection object is null');
        }
        if (regAddr == null || !validRegisters.has(regAddr)) {
            throw new Error('heartRateReadData - invalid register address');
        }
        // write the register to read
        await this.heartRateTX([regAddr], 1);
        // send i2c read command
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY,
            this.interfaceID,
            size,
        ]);
        // send READ_DIGITAL_INTERFACE_X cmd
        if (this.interfaceID == 1) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_1 << 1) + 1,
            ]);
        }
        else if (this.interfaceID == 2) {
            await this.commandQueue.addWithoutValidation([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_2 << 1) + 1,
            ]);
        }
        else {
            throw new Error('heartRateReadData - invalid interface ID');
        }
        // read back and process response
        await this.commandQueue.addRead().then((result) => {
            this.data = [];
            for (let i = 2; i < result.byteLength; i++) {
                this.data.push(result.getUint8(i));
            }
            this.processData(this.data);
        });
    }
    async init(commandQueue, port, customParams) {
        this.commandQueue = commandQueue;
        this.interfaceID = port;
        this.heartrate = 0;
        this.confidence = 0;
        this.blood_oxygen = 0;
        this.status = 0;
        this.err_code = HeartRateRetcode.HEARTRATE_SUCCESS;
        // clear rx and tx buffers
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.CLEAR_BUFFER,
            this.interfaceID,
        ]);
        // read the WHO AM I register
        await this.heartRateReadData(WHO_AM_I, WHO_AM_I_SIZE);
        this.err_code = await this.disable_polling();
        this.err_code = await this.set_polling_interval(1000);
        this.err_code = await this.set_polling_mode(OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.I2C_READ_ONLY);
        // await this.heartRateTX([READ_ALL], 1);
        await this.heartRateReadData(READ_ALL, ALL_SIZE); // send a read command of the desired data
        this.err_code = await this.enable_polling();
        if (this.err_code == HeartRateRetcode.HEARTRATE_SUCCESS) {
            return true;
        }
        else {
            return false;
        }
    }
    // need to disable polling when clicking the disconnect button
    async disconnect() {
        await this.disable_polling();
    }
    async enable_polling() {
        if (this.i2c_polling_interval == null ||
            this.i2c_polling_interval == 0 ||
            this.i2c_polling_mode == null) {
            throw new Error('heart rate enable_polling - polling interval and mode arent set so cannot enable polling');
        }
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.ON,
        ]);
        return this.err_code;
    }
    async disable_polling() {
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN,
            this.interfaceID,
            PLCommandCodes.OFF,
        ]);
        return this.err_code;
    }
    // interval (uint16_t) is in ms
    async set_polling_interval(interval) {
        if (interval < MIN_I2C_POLLING_INTERVAL ||
            interval > MAX_12C_POLLING_INTERVAL) {
            throw new Error('heart rate set_polling_interval - invalid i2c polling interval');
        }
        this.i2c_polling_interval = interval;
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL,
            this.interfaceID,
            this.i2c_polling_interval & 0xff,
            (this.i2c_polling_interval >> 8) & 0xff,
        ]);
        return this.err_code;
    }
    // mode is the given operation to poll
    async set_polling_mode(mode) {
        if (mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_READ ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.UART_WRITE ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_EN ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_INTERVAL ||
            mode == OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE) {
            throw new Error('heart rate set_polling_mode - invalid i2c polling mode');
        }
        this.i2c_polling_mode = mode; // type is defined so any call to this that gives a nonexistent command code doesn't compile
        await this.commandQueue.addWithoutValidation([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.DIGITAL_INTERFACE_CMDS.POLLING_MODE,
            this.interfaceID,
            this.i2c_polling_mode,
        ]);
        return this.err_code;
    }
    processData(data = null) {
        if (data != null) {
            this.data = [];
            this.data = data;
        }
        else {
            return [];
        }
        if (this.data.length == 0) {
            this.err_code = HeartRateRetcode.HEARTRATE_ERR_DATA_EMPTY;
            console.error(`HeartRate data empty, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        let uint8Arr = new Uint8Array(this.data);
        let buffer = uint8Arr.buffer;
        let dataView = new DataView(buffer);
        let i = 0;
        let exitLoop = false;
        while (!exitLoop && i < this.data.length) {
            switch (this.data[i]) {
                case READ_HEARTRATE:
                    i++;
                    this.heartrate = dataView.getUint16(i, true);
                    exitLoop = true;
                    break;
                case READ_CONFIDENCE:
                    i++;
                    this.confidence = this.data[i];
                    exitLoop = true;
                    break;
                case READ_BLOOD_OX:
                    i++;
                    this.blood_oxygen = dataView.getUint16(i, true);
                    exitLoop = true;
                    break;
                case READ_STATUS:
                    i++;
                    this.status = this.data[i];
                    exitLoop = true;
                    break;
                case READ_ALL:
                    i++;
                    this.heartrate = dataView.getUint16(i, true);
                    i += 2;
                    this.confidence = this.data[i];
                    i++;
                    this.blood_oxygen = dataView.getUint16(i, true);
                    i += 2;
                    this.status = this.data[i];
                    exitLoop = true;
                    break;
                case WHO_AM_I:
                    i++;
                    let addr = dataView.getUint8(i);
                    if (i != HeartRate.commConfig.address) {
                        console.error(`WHO AM I register gave wrong I2C address: ${addr}`);
                    }
                    exitLoop = true;
                    break;
                default:
                    // Handle any other unrecognized command
                    this.err_code = HeartRateRetcode.HEARTRATE_ERR_UNRECOGNIZED_CMD;
                    i++;
                    break;
            }
        }
        // reset data
        this.data = [];
        // return values
        return [
            ['Heart Rate', this.heartrate, 'bpm'],
            ['Confidence', this.confidence, '%'],
            ['Blood Oxygen', this.blood_oxygen, '%'],
            ['Status', this.status, ''],
        ];
    }
}
export const HeartRate = {
    name: 'Heart Rate Sensor',
    pwrEn: true,
    pwrMode: DigitalPowerMode.DIGITAL_RAIL_3V3,
    comm: CommProtocol.I2C_External,
    ioEnable: true,
    commConfig: {
        address: 0x7a,
        baudRate: I2CClockSpeed.I2C_CLK_400K,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: new HeartRateDriver(),
};
