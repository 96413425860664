import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  APIMessages as APIM,
  PL2,
  UtilsPL2 as U,
} from '@common/utils/dist/index.js';

import {S3Service} from './s3.service';

@Injectable()
export class WSMessageReceiverService {
  constructor(private s3Service: S3Service) {}

  receive<T extends APIM.Request<any>>(
    msg: APIM.WebSocketResponseEnvelope,
  ): Observable<T> {
    const data = JSON.parse(msg.data ?? '{}') as T;
    let readMessage = () => of(data);
    if (!U.isEmpty((<any>data).Key)) {
      readMessage = () => this._receive((<any>data).Key);
    }
    return readMessage();
  }

  private _receive<T>(key: string): Observable<T> {
    return this.s3Service
      .getFile(key)
      .pipe(map((response: string) => JSON.parse(response) as T));
  }
}
