import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';

import {
  PL2,
  ErrorUtils as ErrU,
  UtilsPL2 as U,
  APIMessages as APIM,
} from '@common/utils/dist/index.js';

import {EntryStore} from '@stores/entry.store';
import {
  CacheExpirationEvent,
  CacheExpirationStrategyService,
} from '@services/cache-expiration-strategy.service';
import {
  Action as AdvanceAction,
  AdvanceWithActionTransformer,
} from '@cloudlab/stores/entry-store-transformers/advance-with-action.transformer';

import {Action} from './action';

export interface ReceiveEventAcknowledgementParams {
  payload: Array<APIM.EntryChange>;
  error: ErrU.ErrorPayload;
}

@Injectable()
export class ReceiveEventAcknowledgementAction
  implements Action<ReceiveEventAcknowledgementParams, boolean>
{
  constructor(
    private entryStore: EntryStore,
    private cacheExpirationStrategy: CacheExpirationStrategyService,
    private advanceWithActionTransformer: AdvanceWithActionTransformer,
  ) {}

  execute(params: ReceiveEventAcknowledgementParams): Observable<boolean> {
    console.debug('[ReceiveEventAcknowledgementAction]payload', params.payload);
    const payload = (params.payload ?? []).filter(
      (ev) => !U.isEmpty(ev?.a) && ev.a !== PL2.EntryAction.Read,
    );
    if (U.isEmpty(payload)) {
      return of(true);
    }

    if (U.isEmpty(params.error)) {
      this.cacheExpirationStrategy.deepExpire(
        CacheExpirationEvent.Success,
        this.entryStore,
      );
      this.entryStore.modifyStateWithTransformer(
        {
          payload: payload,
          action: AdvanceAction.Succeeded,
        },
        this.advanceWithActionTransformer,
      );
    } else {
      this.entryStore.modifyStateWithTransformer(
        {
          payload: payload,
          error: {status: params.error.code, message: params.error.message},
          action: AdvanceAction.Failed,
        },
        this.advanceWithActionTransformer,
      );
    }
    return of(true);
  }
}
