import { EntryType, SectionScope, } from '../models/models-pl2.js';
import { UtilsPL2 as U } from './utils-pl2.js';
import { KeyUtilsPL2 as KU } from './key-utils-pl2.js';
export var EntryUtilsPL2;
(function (EntryUtilsPL2) {
    function isSection(o) {
        const t = KU.type(o);
        return '20' <= t && t <= '39';
    }
    EntryUtilsPL2.isSection = isSection;
    function isLabReportLike(o) {
        const t = KU.type(o);
        return '01' <= t && t <= '07';
    }
    EntryUtilsPL2.isLabReportLike = isLabReportLike;
    function isChildSection(e) {
        if (!e) {
            return false;
        }
        return [
            EntryType.AnswerSection,
            EntryType.Transcript,
            EntryType.Comment,
            EntryType.TeacherNotes,
        ].includes(e.t);
    }
    EntryUtilsPL2.isChildSection = isChildSection;
    function isTLE(e) {
        return isTLEType(e.t || KU.type(e));
    }
    EntryUtilsPL2.isTLE = isTLE;
    function isTLEType(entryType) {
        if (entryType >= '00' && entryType < '10') {
            return true;
        }
        return false;
    }
    EntryUtilsPL2.isTLEType = isTLEType;
    function isUserEntries(pK) {
        const sortKey = KU.parseSortKey(pK.substring(8));
        return '10' <= sortKey.nodeId.t && sortKey.nodeId.t <= '19';
    }
    EntryUtilsPL2.isUserEntries = isUserEntries;
    function isUserEntry(sK) {
        const sortKey = KU.parseSortKey(sK);
        return '10' <= sortKey.nodeId.t && sortKey.nodeId.t <= '19';
    }
    EntryUtilsPL2.isUserEntry = isUserEntry;
    function isScopeEntry(o) {
        const t = KU.type(o);
        return '05' <= t && t <= '07';
    }
    EntryUtilsPL2.isScopeEntry = isScopeEntry;
    EntryUtilsPL2.entryIdAtts = ['mId', 'sK'];
    EntryUtilsPL2.entryAtts = [
        't',
        'uId',
        'rC',
        'cAt',
        'uAt',
        'n',
        'isA',
        ...EntryUtilsPL2.entryIdAtts,
    ];
    EntryUtilsPL2.userLibraryEntryIdxEntryAtts = ['uLEId'];
    EntryUtilsPL2.dashboardIdxEntryAtts = ['mId', 'dSK'];
    EntryUtilsPL2.authIdxEntryAtts = ['aSK', 'mId'];
    EntryUtilsPL2.userEntryAtts = ['mUId', 'cN', 'mUR', 'gPId', ...EntryUtilsPL2.entryAtts];
    EntryUtilsPL2.sortableEntryAtts = ['lId', 'pos'];
    EntryUtilsPL2.shareableAtts = ['isP'];
    EntryUtilsPL2.taggableAtts = ['tgs'];
    EntryUtilsPL2.sourceableAtts = ['srcId'];
    EntryUtilsPL2.referenceableAtts = ['refId'];
    EntryUtilsPL2.altNameableAtts = ['altN'];
    EntryUtilsPL2.lmsAssignableAtts = ['lmsAId', 'lmsUId'];
    EntryUtilsPL2.tleMetadataAtts = ['dsc', 'ord', 'cat', 'aut', 'ico'];
    EntryUtilsPL2.sectionEntryAtts = [
        'n',
        'uN',
        'pos',
        'isC',
        'sS',
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.dashboardIdxEntryAtts,
        ...EntryUtilsPL2.sortableEntryAtts,
    ];
    EntryUtilsPL2.notatableSectionEntryAtts = ['isN', 'con', ...EntryUtilsPL2.sectionEntryAtts];
    EntryUtilsPL2.baseLabReportEntryAtts = [
        'n',
        'dsc',
        'pI',
        'mCats',
        ...EntryUtilsPL2.tleMetadataAtts,
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.authIdxEntryAtts,
        ...EntryUtilsPL2.referenceableAtts,
    ];
    EntryUtilsPL2.answerEntryAtts = [
        'n',
        'uN',
        'isC',
        'sS',
        'ans',
        'oI',
        'pos',
        ...EntryUtilsPL2.entryAtts,
    ];
    EntryUtilsPL2.classIdxEntryAtts = ['mId', 'cSK', ...EntryUtilsPL2.entryIdAtts];
    EntryUtilsPL2.themableAtts = ['th', 'sST'];
    EntryUtilsPL2.autoGradingToggler = ['aG'];
    EntryUtilsPL2.referenceEntryAtts = ['sMId', 'sSK'];
    EntryUtilsPL2.assignmentEntryIdAtts = [
        'uLG',
        'sCCt',
        'isC',
        'tmp',
        'shC',
        'lmsCId',
        'lmsUIds',
        ...EntryUtilsPL2.autoGradingToggler,
        ...EntryUtilsPL2.baseLabReportEntryAtts,
        ...EntryUtilsPL2.dashboardIdxEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
        ...EntryUtilsPL2.sortableEntryAtts,
        ...EntryUtilsPL2.themableAtts,
        ...EntryUtilsPL2.lmsAssignableAtts,
        ...EntryUtilsPL2.referenceEntryAtts,
    ];
    EntryUtilsPL2.classCodeIdxEntryAtts = ['cC'];
    EntryUtilsPL2.classEntryAtts = [
        'n',
        'sN',
        'cC',
        'per',
        'sub',
        'rm',
        'uSP',
        'gCId',
        'cCId',
        'edCId',
        'exUrl',
        'tmp',
        'eCId',
        'lmsCId',
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.authIdxEntryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
    ];
    EntryUtilsPL2.commentEntryAtts = ['cmt', 'uN', 'qF', ...EntryUtilsPL2.entryAtts];
    EntryUtilsPL2.teacherNotesEntryAtts = ['nts', 'tgs', ...EntryUtilsPL2.entryAtts];
    EntryUtilsPL2.dataAnalysisSectionEntryAtts = [
        'dAT',
        'col',
        'dat',
        'xL',
        'yL',
        'yR',
        ...EntryUtilsPL2.sectionEntryAtts,
    ];
    EntryUtilsPL2.dataTableCellAtts = [
        'value',
        'r',
        'c',
        'id',
        'uId',
        'ts',
        'iA',
        's',
    ];
    EntryUtilsPL2.dataConfigViewAttrs = [
        'visIdx',
        'pos',
        'f',
        'fIdx',
        'l',
        'xL',
        'yL',
        'n',
        'dsV',
        'p',
        'sD',
        'loc',
        'uIdx',
    ];
    EntryUtilsPL2.globalResourceEntryAtts = [
        'n',
        'url',
        'dsc',
        'cat',
        'pI',
        'ord',
        ...EntryUtilsPL2.entryAtts,
    ];
    EntryUtilsPL2.googleDriveSectionEntryAtts = ['url', ...EntryUtilsPL2.sectionEntryAtts];
    EntryUtilsPL2.iframeSectionEntryAtts = [
        'url',
        'hPx',
        ...EntryUtilsPL2.notatableSectionEntryAtts,
    ];
    EntryUtilsPL2.imageSectionEntryAtts = [
        'iA',
        'iAN',
        'cpt',
        'alt',
        ...EntryUtilsPL2.notatableSectionEntryAtts,
    ];
    EntryUtilsPL2.phetSectionEntryAtts = ['sUrl', ...EntryUtilsPL2.notatableSectionEntryAtts];
    EntryUtilsPL2.sharedObjectDataAtts = ['dat'];
    EntryUtilsPL2.sharedDataTableSectionEntryAtts = [
        'dT',
        ...EntryUtilsPL2.sharedObjectDataAtts,
        ...EntryUtilsPL2.notatableSectionEntryAtts,
    ];
    EntryUtilsPL2.sharedDrawingSectionEntryAtts = [
        'sca',
        'h',
        'bg',
        'cA',
        ...EntryUtilsPL2.sharedObjectDataAtts,
        ...EntryUtilsPL2.notatableSectionEntryAtts,
    ];
    EntryUtilsPL2.videoSectionEntryAtts = [
        'yA',
        'cpt',
        ...EntryUtilsPL2.notatableSectionEntryAtts,
    ];
    EntryUtilsPL2.labReportEntryAtts = [
        'sN',
        'scC',
        'vN',
        ...EntryUtilsPL2.baseLabReportEntryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
        ...EntryUtilsPL2.sortableEntryAtts,
        ...EntryUtilsPL2.shareableAtts,
        ...EntryUtilsPL2.sourceableAtts,
        ...EntryUtilsPL2.themableAtts,
    ];
    EntryUtilsPL2.labReportUserEntryAtts = [
        'lGI',
        'clsN',
        'cN',
        'pI',
        'sE',
        'lmsCId',
        ...EntryUtilsPL2.userEntryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
        ...EntryUtilsPL2.authIdxEntryAtts,
        ...EntryUtilsPL2.dashboardIdxEntryAtts,
        ...EntryUtilsPL2.lmsAssignableAtts,
    ];
    EntryUtilsPL2.invitedLabReportUserEntryAtts = [
        'iId',
        ...EntryUtilsPL2.labReportUserEntryAtts,
    ];
    EntryUtilsPL2.uninvitedLabReportUserEntryAtts = [
        'cN',
        ...EntryUtilsPL2.userEntryAtts,
        ...EntryUtilsPL2.authIdxEntryAtts,
    ];
    EntryUtilsPL2.questionSectionEntryAtts = [
        'qs',
        'opts',
        'cA',
        'hts',
        'cHt',
        'mS',
        ...EntryUtilsPL2.sectionEntryAtts,
    ];
    EntryUtilsPL2.studentCopyEntryAtts = [
        'tIAt',
        'nIA',
        'lGI',
        ...EntryUtilsPL2.autoGradingToggler,
        ...EntryUtilsPL2.baseLabReportEntryAtts,
        ...EntryUtilsPL2.dashboardIdxEntryAtts,
        ...EntryUtilsPL2.themableAtts,
    ];
    EntryUtilsPL2.studentUserEntryAtts = [
        'pCC',
        'uC',
        ...EntryUtilsPL2.userEntryAtts,
        ...EntryUtilsPL2.dashboardIdxEntryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
    ];
    EntryUtilsPL2.textSectionEntryAtts = ['con', 'cSK', ...EntryUtilsPL2.sectionEntryAtts];
    EntryUtilsPL2.baseTrialEntryAtts = [
        'f',
        'cfgIds',
        'loc',
        'sD',
        'pP',
        'nts',
        'n',
        'vS',
        'dM',
        'zR',
        'cA',
        ...EntryUtilsPL2.entryAtts,
    ];
    EntryUtilsPL2.trialEntryAtts = [
        ...EntryUtilsPL2.baseTrialEntryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
    ];
    EntryUtilsPL2.trialSectionEntryAtts = [
        ...EntryUtilsPL2.baseTrialEntryAtts,
        ...EntryUtilsPL2.sectionEntryAtts,
    ];
    EntryUtilsPL2.collectDataSectionEntryAtts = ['dCSP', ...EntryUtilsPL2.sectionEntryAtts];
    EntryUtilsPL2.drawingObjectAtts = [
        'id',
        'uId',
        'ts',
        'iA',
        't',
        'x',
        'y',
        'w',
        'h',
        'a',
        'sX',
        'sY',
        'c',
        'txt',
        'src',
        'r',
        's',
        'x1',
        'x2',
        'y1',
        'y2',
        'p',
        'ff',
        'sW',
        'fs',
        'fY',
        'fX',
    ];
    EntryUtilsPL2.dragAndDropObjectAtts = [
        'isC',
        'atpts',
        'sO',
        'tO',
        'hT',
        ...EntryUtilsPL2.drawingObjectAtts,
    ];
    EntryUtilsPL2.localSubsetEntryAtts = [...EntryUtilsPL2.sharedObjectDataAtts, ...EntryUtilsPL2.entryAtts];
    EntryUtilsPL2.moduleEntryIdAtts = [
        'dsc',
        ...EntryUtilsPL2.localSubsetEntryAtts,
        ...EntryUtilsPL2.sortableEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
    ];
    EntryUtilsPL2.classLocalSubsetEntryAtts = [
        ...EntryUtilsPL2.localSubsetEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
    ];
    EntryUtilsPL2.scopeEntryAtts = [
        'tIAt',
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.authIdxEntryAtts,
        ...EntryUtilsPL2.dashboardIdxEntryAtts,
    ];
    EntryUtilsPL2.scopeUserEntryAtts = [...EntryUtilsPL2.userEntryAtts, ...EntryUtilsPL2.authIdxEntryAtts];
    EntryUtilsPL2.turnInAbleAtts = ['tIAt', 'uAt'];
    EntryUtilsPL2.courseEntryAtts = [
        'n',
        'pI',
        'chk',
        'scC',
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.authIdxEntryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
        ...EntryUtilsPL2.shareableAtts,
        ...EntryUtilsPL2.sortableEntryAtts,
        ...EntryUtilsPL2.referenceEntryAtts,
        ...EntryUtilsPL2.tleMetadataAtts,
    ];
    EntryUtilsPL2.curriculumEntryAtts = [
        'n',
        'scC',
        'flts',
        'altNs',
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.authIdxEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
        ...EntryUtilsPL2.shareableAtts,
        ...EntryUtilsPL2.tleMetadataAtts,
    ];
    EntryUtilsPL2.placeholderEntryAtts = [
        'pI',
        'mUId',
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.referenceEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
    ];
    EntryUtilsPL2.licenseEntryAtts = [
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
        'mUId',
        'nG',
        'curPK',
        'scC',
        'numM',
        'altN',
        'subId',
    ];
    EntryUtilsPL2.classPermissionEntryAtts = [
        ...EntryUtilsPL2.entryAtts,
        ...EntryUtilsPL2.authIdxEntryAtts,
        ...EntryUtilsPL2.userLibraryEntryIdxEntryAtts,
        ...EntryUtilsPL2.classIdxEntryAtts,
        'mUId',
        'cPT',
    ];
    EntryUtilsPL2.sharedDragAndDropSectionEntryAtts = [
        'iGM',
        'mE',
        ...EntryUtilsPL2.sharedDrawingSectionEntryAtts,
    ];
    function resetEntry(entry) {
        const e = { ...entry };
        delete e.isP;
        delete e.uLEId;
        delete e.dSK;
        delete e.cSK;
        delete e.aSK;
        delete e.sN;
        delete e.srcId;
        delete e.scC;
        delete e.sK;
        delete e.isA;
        delete e.mId;
        delete e.uId;
        delete e.cAt;
        delete e.uAt;
        delete e.rC;
        delete e._lvl;
        delete e._tMMYY;
        return e;
    }
    EntryUtilsPL2.resetEntry = resetEntry;
    function isSortable(e) {
        if (isSection(e)) {
            return true;
        }
        const t = KU.type(e);
        switch (t) {
            case EntryType.Assignment:
            case EntryType.Module:
            case EntryType.LabReport:
            case EntryType.Course:
                return true;
            default:
                return false;
        }
    }
    EntryUtilsPL2.isSortable = isSortable;
    function isLocalSubset(e) {
        const t = KU.type(e);
        switch (t) {
            case EntryType.LocalSubset:
            case EntryType.Module:
            case EntryType.ClassLocalSubset:
                return true;
            default:
                return false;
        }
    }
    EntryUtilsPL2.isLocalSubset = isLocalSubset;
    function getSectionScope(entry) {
        return !U.isEmpty(entry.sS) ? entry.sS : SectionScope.Group;
    }
    EntryUtilsPL2.getSectionScope = getSectionScope;
    function isValidSectionScope(scope) {
        return (!U.isEmpty(scope) &&
            [
                SectionScope.Class,
                SectionScope.Group,
                SectionScope.Individual,
            ].includes(scope));
    }
    EntryUtilsPL2.isValidSectionScope = isValidSectionScope;
    function isActive(entry) {
        return entry?.isA === 'y';
    }
    EntryUtilsPL2.isActive = isActive;
    function isNotActive(entry) {
        return U.isEmpty(entry?.isA) || entry?.isA === 'n';
    }
    EntryUtilsPL2.isNotActive = isNotActive;
    function activate(entry) {
        entry.isA = 'y';
        return entry;
    }
    EntryUtilsPL2.activate = activate;
    function deactivate(entry) {
        if (KU.isType(EntryType.Class, entry)) {
            entry.isA = 'n';
        }
        else {
            entry.isA = null;
        }
        return entry;
    }
    EntryUtilsPL2.deactivate = deactivate;
})(EntryUtilsPL2 || (EntryUtilsPL2 = {}));
