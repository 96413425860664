import produce from 'immer';
import {Injectable} from '@angular/core';
import {
  APIMessages as APIM,
  PL2,
  UtilsPL2 as U,
  KeyUtilsPL2 as KU,
} from '@common/utils/dist/index.js';
import {APIError} from '@utils/api-errors';
import {
  PendingOperation,
  OperationStateMachine,
} from '../entry-store-automaton/operation-state-machine';
import {StateTransformer} from '../abstract-store';
import {EntryState} from '../entry.store';

export enum Action {
  Sent,
  Succeeded,
  Failed,
}

export interface AdvancedData {
  error?: {status: number; message: any};
  payload: APIM.EntryChange[];
  action: Action;
}

type ActionMap = {[key: number]: PendingOperation};

@Injectable({
  providedIn: 'root',
})
export class AdvanceWithActionTransformer
  implements StateTransformer<AdvancedData, EntryState>
{
  hasChanged = false;

  private readonly _sentEntryActionMap: ActionMap = {
    [PL2.EntryAction.Create]: PendingOperation.CreateSent,
    [PL2.EntryAction.Update]: PendingOperation.UpdateSent,
    [PL2.EntryAction.Delete]: PendingOperation.DeleteSent,
    [PL2.EntryAction.DeepDelete]: PendingOperation.DeleteSent,
  };

  private readonly _succeededEntryActionMap: ActionMap = {
    [PL2.EntryAction.Create]: PendingOperation.CreateSucceeded,
    [PL2.EntryAction.Update]: PendingOperation.UpdateSucceeded,
    [PL2.EntryAction.Delete]: PendingOperation.DeleteSucceeded,
    [PL2.EntryAction.DeepDelete]: PendingOperation.DeleteSucceeded,
  };

  private readonly _failedEntryActionMap: ActionMap = {
    [PL2.EntryAction.Create]: PendingOperation.CreateFailed,
    [PL2.EntryAction.Update]: PendingOperation.UpdateFailed,
    [PL2.EntryAction.Delete]: PendingOperation.DeleteFailed,
    [PL2.EntryAction.DeepDelete]: PendingOperation.DeleteFailed,
  };

  constructor(private operationStateMachine: OperationStateMachine) {}

  transform(data: AdvancedData, state: EntryState): EntryState {
    this.hasChanged = false;
    if (U.isEmpty(data.payload)) {
      return state;
    }
    const actionMap = this._actionMap(data.action);
    return produce(state, (draftState) => {
      data.payload.forEach((c) => {
        const pK = KU.stringFromKey(c.p as PL2.EntryId);
        if (!U.isEmpty(draftState[pK])) {
          const modification = this.operationStateMachine.nextState(
            actionMap[c.a],
            draftState[pK]._pO,
          );
          modification.modify(c.p, draftState, data.error as APIError.Error);
          this.hasChanged = true;
        }
      });
    });
  }

  private _actionMap(action: Action): ActionMap {
    switch (action) {
      case Action.Sent:
        return this._sentEntryActionMap;
      case Action.Succeeded:
        return this._succeededEntryActionMap;
      case Action.Failed:
        return this._failedEntryActionMap;
    }
  }
}
