import { DigitalInterfaceSensors, editDigitalInterface, } from './odyssey-digital-interface.js';
import { OdysseySystemConfig } from './odyssey-system-config.js';
import { SYSTEM_CONFIG } from './pl-command-codes.js';
export class SecondaryConfigPipeline {
    execute(sensorIds, frequencyIndex, deviceConfig, commandQueue) {
        const strategies = new Array();
        strategies.push(new SensorCommandConfigStep());
        strategies.push(new FrequencyCommandConfigStep());
        strategies.push(new SystemConfigStep());
        strategies.push(new DigitalSensorInitStep());
        strategies.forEach((strategy) => {
            strategy.execute({
                sensorIds,
                frequencyIndex,
                deviceConfig,
                commandQueue,
            });
        });
    }
}
export class SensorCommandConfigStep {
    execute(context) {
        context.sensorIds.forEach((sensorId) => {
            const sensorConfig = context.deviceConfig.sensorConfigs[sensorId];
            const secondaryConfig = sensorConfig.secondaryConfig;
            if (!!secondaryConfig?.command) {
                context.commandQueue.addWithoutValidation(secondaryConfig.command);
            }
        });
        return context;
    }
}
export class FrequencyCommandConfigStep {
    execute(context) {
        const frequencyConfig = context.deviceConfig?.frequencyConfigs[context.frequencyIndex]?.sC;
        if (!!frequencyConfig) {
            context.commandQueue.addWithoutValidation(frequencyConfig);
        }
        return context;
    }
}
export class SystemConfigStep {
    execute(context) {
        const odysseySystemConfig = new OdysseySystemConfig();
        const newSystemConfigs = {};
        context.sensorIds.forEach((sensorId) => {
            const sensorConfig = context.deviceConfig.sensorConfigs[sensorId];
            const secondaryConfig = sensorConfig.secondaryConfig;
            if (!!secondaryConfig?.system) {
                const register = odysseySystemConfig.registers[secondaryConfig.system.registerIdx];
                const key = `${register.configCommand}${register.index}`;
                if (!newSystemConfigs[key]) {
                    newSystemConfigs[key] = register.defaultRegisterValues;
                }
                if (typeof secondaryConfig.disIdx === 'number') {
                    const dis = DigitalInterfaceSensors[secondaryConfig.disIdx];
                    editDigitalInterface(dis, register);
                    newSystemConfigs[key] = register.value;
                    // configureDigitalInterface(register.index, dis, odysseySystemConfig, context.commandQueue)
                }
                else {
                    const mask = register.content[secondaryConfig.system.enableBitIdx].mask;
                    newSystemConfigs[key] = newSystemConfigs[key] | mask;
                }
            }
        });
        for (const [key, cfg] of Object.entries(newSystemConfigs)) {
            const configCommand = parseInt(key[0], 10);
            const configInterface = parseInt(key[1], 10);
            // const config = new Uint8Array(new Uint32Array([cfg]).buffer);
            const config = new Uint8Array(this._toUint8Array(cfg));
            context.commandQueue.addWithoutValidation([SYSTEM_CONFIG << 1, configCommand, configInterface, ...config], 1000);
        }
        return context;
    }
    _toUint8Array(value) {
        // Calculate how many bytes we need
        const byteLength = (value.toString(16).length + 1) >> 1;
        const result = new Uint8Array(byteLength);
        // Extract each byte
        for (let i = 0; i < byteLength; i++) {
            // Extract the lowest 8 bits and place at position i (little-endian)
            result[i] = Number(value & 0xffn);
            // Shift right by 8 bits
            value = value >> 8n;
        }
        return result;
    }
}
export class DigitalSensorInitStep {
    execute(context) {
        const odysseySystemConfig = new OdysseySystemConfig();
        context.sensorIds.forEach((sensorId) => {
            const sensorConfig = context.deviceConfig.sensorConfigs[sensorId];
            const secondaryConfig = sensorConfig.secondaryConfig;
            if (!!secondaryConfig?.system &&
                typeof secondaryConfig.disIdx === 'number') {
                const register = odysseySystemConfig.registers[secondaryConfig.system.registerIdx];
                const dis = DigitalInterfaceSensors[secondaryConfig.disIdx];
                dis.handler.init(context.commandQueue, register.index, {});
            }
        });
        return context;
    }
}
