export var IndexManagerChainType;
(function (IndexManagerChainType) {
    IndexManagerChainType[IndexManagerChainType["Default"] = 0] = "Default";
    IndexManagerChainType[IndexManagerChainType["WithoutSrcId"] = 1] = "WithoutSrcId";
    IndexManagerChainType[IndexManagerChainType["AcceptInvite"] = 2] = "AcceptInvite";
})(IndexManagerChainType || (IndexManagerChainType = {}));
export var Service;
(function (Service) {
    Service[Service["AI"] = -1] = "AI";
    Service[Service["Entry"] = 0] = "Entry";
})(Service || (Service = {}));
export var Route;
(function (Route) {
    Route["Default"] = "default";
    Route["Respond"] = "respond";
    Route["Ping"] = "ping";
})(Route || (Route = {}));
export var RequestIntent;
(function (RequestIntent) {
    RequestIntent[RequestIntent["WSSEnhanceLesson"] = 0] = "WSSEnhanceLesson";
    RequestIntent[RequestIntent["WSSNotifications"] = 1] = "WSSNotifications";
    RequestIntent[RequestIntent["WSSGenerateMaterial"] = 2] = "WSSGenerateMaterial";
    RequestIntent[RequestIntent["WSSQuery"] = 3] = "WSSQuery";
    RequestIntent[RequestIntent["WSSEntryChange"] = 4] = "WSSEntryChange";
    RequestIntent[RequestIntent["WSSConnect"] = 5] = "WSSConnect";
    RequestIntent[RequestIntent["WSSDisconnect"] = 6] = "WSSDisconnect";
    RequestIntent[RequestIntent["WSSNotify"] = 7] = "WSSNotify";
    RequestIntent[RequestIntent["WSSSubjectlessNotify"] = 8] = "WSSSubjectlessNotify";
    RequestIntent[RequestIntent["WSSWatch"] = 9] = "WSSWatch";
    RequestIntent[RequestIntent["WSSPing"] = 10] = "WSSPing";
    RequestIntent[RequestIntent["AppAcknowledge"] = 11] = "AppAcknowledge";
    RequestIntent[RequestIntent["AppRespondToFetch"] = 12] = "AppRespondToFetch";
    RequestIntent[RequestIntent["AppNotify"] = 13] = "AppNotify";
    RequestIntent[RequestIntent["AppError"] = 14] = "AppError";
    RequestIntent[RequestIntent["AppRefreshUser"] = 15] = "AppRefreshUser";
    RequestIntent[RequestIntent["AppPong"] = 16] = "AppPong";
    RequestIntent[RequestIntent["EntryBatchUpdate"] = 17] = "EntryBatchUpdate";
    RequestIntent[RequestIntent["EntryCreate"] = 18] = "EntryCreate";
    RequestIntent[RequestIntent["EntryCreateTempInvite"] = 19] = "EntryCreateTempInvite";
    RequestIntent[RequestIntent["EntryCreateUninitialized"] = 20] = "EntryCreateUninitialized";
    RequestIntent[RequestIntent["EntryUpdate"] = 21] = "EntryUpdate";
    RequestIntent[RequestIntent["EntryDelete"] = 22] = "EntryDelete";
    RequestIntent[RequestIntent["EntryDeepDelete"] = 23] = "EntryDeepDelete";
    RequestIntent[RequestIntent["EntryUndoDelete"] = 24] = "EntryUndoDelete";
    RequestIntent[RequestIntent["EntryRead"] = 25] = "EntryRead";
    // begin exodus
    RequestIntent[RequestIntent["EntryMigrateExistingDAUser"] = 26] = "EntryMigrateExistingDAUser";
    RequestIntent[RequestIntent["EntrySynchronizeEdClasses"] = 27] = "EntrySynchronizeEdClasses";
    RequestIntent[RequestIntent["EntryUpdateHubspotWithUser"] = 28] = "EntryUpdateHubspotWithUser";
    RequestIntent[RequestIntent["EntrySynchronizeEdlinkClasses"] = 29] = "EntrySynchronizeEdlinkClasses";
    RequestIntent[RequestIntent["EntrySynchronizeGoogleClasses"] = 30] = "EntrySynchronizeGoogleClasses";
    // end exodus
    RequestIntent[RequestIntent["NotificationPushSubject"] = 31] = "NotificationPushSubject";
    RequestIntent[RequestIntent["NotificationSupressSubject"] = 32] = "NotificationSupressSubject";
    RequestIntent[RequestIntent["NotificationWithoutSubject"] = 33] = "NotificationWithoutSubject";
    RequestIntent[RequestIntent["UserSetupEdlinkUser"] = 34] = "UserSetupEdlinkUser";
})(RequestIntent || (RequestIntent = {}));
/***************
 * AI service *
 ***************/
export var AiEventAction;
(function (AiEventAction) {
    AiEventAction[AiEventAction["Create"] = 0] = "Create";
})(AiEventAction || (AiEventAction = {}));
/***************
 * Entry service *
 ****************/
export var EntryEventAction;
(function (EntryEventAction) {
    EntryEventAction[EntryEventAction["Create"] = 0] = "Create";
    EntryEventAction[EntryEventAction["Read"] = 1] = "Read";
    EntryEventAction[EntryEventAction["Update"] = 2] = "Update";
    EntryEventAction[EntryEventAction["Delete"] = 3] = "Delete";
})(EntryEventAction || (EntryEventAction = {}));
/***********************
 * WebSocket Service ****
 ***********************/
export var WSSEventAction;
(function (WSSEventAction) {
    WSSEventAction[WSSEventAction["Connect"] = 0] = "Connect";
    WSSEventAction[WSSEventAction["Disconnect"] = 1] = "Disconnect";
    WSSEventAction[WSSEventAction["Notify"] = 2] = "Notify";
    WSSEventAction[WSSEventAction["Watch"] = 3] = "Watch";
})(WSSEventAction || (WSSEventAction = {}));
export var UserAction;
(function (UserAction) {
    UserAction[UserAction["SignIn"] = 0] = "SignIn";
})(UserAction || (UserAction = {}));
export var UserPoolUserAction;
(function (UserPoolUserAction) {
    UserPoolUserAction[UserPoolUserAction["SetupEdlinkUser"] = 0] = "SetupEdlinkUser";
    // UpdateUser,
})(UserPoolUserAction || (UserPoolUserAction = {}));
