import produce from 'immer';

import {Inject, Injectable, OnDestroy} from '@angular/core';

import {ReplaySubject, Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {
  CacheExpirationEvent,
  CacheExpirationStrategyService,
} from '@services/cache-expiration-strategy.service';

import {Store} from '@stores/abstract-store';
import {
  PendingOperation,
  OperationStateMachine,
} from './entry-store-automaton/operation-state-machine';
import {
  ReceiveCreateStrategy,
  ReceiveDeleteStrategy,
  ReceiveUpdateStrategy,
} from './entry-store-automaton/modification-strategy';

import {
  APIMessages as APIM,
  PL2,
  EntryIndexAttributeInitializer,
  UtilsPL2 as U,
  KeyUtilsPL2 as KU,
  TypeUtilsPL2 as TU,
} from '@common/utils/dist/index.js';

import {EntryStoreUtils as ESU} from '@utils/entry-store-utils';

import {entryDebounceTime} from '@constants/app.config';

import {APIError} from '../utils/api-errors';

export interface CachedEntry {
  e: Partial<PL2.AnyEntry>;
  c: Array<string>;
  _ts: string;
  _payload?: PL2.AnyPartialEntryWithId[];
  _pO: PendingOperation;
  _err?: APIError.Error;
}

type DefaultState = {
  [key: string]: CachedEntry;
};

type TypedEntryState = TU.BuildEntryState<PendingOperation, APIError.Error>;

export type EntryState = TypedEntryState & DefaultState;

@Injectable({
  providedIn: 'root',
})
export class EntryStore extends Store<EntryState> implements OnDestroy {
  debounceState$ = this.state$.pipe(
    debounceTime(entryDebounceTime),
  ) as Observable<{}>;

  private _currentUId: string;
  private _destroyed$ = new ReplaySubject<boolean>();

  constructor(
    @Inject('STORAGE') private localStorage: any,
    private cacheExpirationStrategy: CacheExpirationStrategyService,
    private operationStateMachine: OperationStateMachine,
  ) {
    super({});
  }

  saveToStorage(): boolean {
    if (this._currentUId) {
      const pES = {};
      Object.values(this.state())
        .sort(U.sortBy('sK', U.oBy.asc))
        .forEach((ce) => {
          if (ce._pO !== PendingOperation.None) {
            const pK = KU.stringFromKey(ce.e as PL2.EntryId);
            pES[pK] = ce;
            const pPK = KU.parentPK(pK);
            const pCE = this.state()[pPK];
            if (!U.isEmpty(pCE)) {
              pES[pPK] = {...pCE};
              pES[pPK].c = pCE.c.filter((cPK) => !!pES[cPK]);
            }
          }
        });
      // Only set the local state if there are pending operations to avoid inactive tabs from clearing pending
      // operations when receiving signout broadcast messages. Also, the local storage is cleared after loading
      if (!U.isEmpty(pES)) {
        this.localStorage.setItem(this._currentUId, JSON.stringify(pES));
        return !ESU.isEntryStateSynchronized(pES);
      } else {
        return false;
      }
      // Reseting the state shouldn't be necessary here because if it is called as a result of a user change
      // then the window and state reset anyway
      // this.setState({});
    }
  }

  loadStorage(userId: string) {
    this._currentUId = userId;
    if (!!this._currentUId) {
      const storageState = this.localStorage.getItem(this._currentUId);
      if (!U.isEmpty(storageState)) {
        const loadedState = JSON.parse(storageState);
        this.cacheExpirationStrategy.expire(
          CacheExpirationEvent.LoadCache,
          loadedState,
        );
        // save existing state in case responses to public requests come in prior to authentication
        const existingState = this.state();
        this.setState(loadedState);
        this.modifyState(
          Object.values(existingState).map(
            (ce) => ce.e,
          ) as PL2.AnyPartialEntryWithId[],
        );
        // If the local storage state is loaded into the entry state then the local storage should be clear
        // and only when unloading the entry state should the state be put back into the local storage
        this.localStorage.setItem(this._currentUId, '{}');
      }
    }
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  create(
    request: Partial<APIM.EntryCreateRequest>,
    isDelayed = false,
  ): PL2.AnyEntry[] {
    const ts = U.timestamp();
    const {response, entries} = new EntryIndexAttributeInitializer().init(
      request as any, // TODO add user object and remove second argument
      this._currentUId,
    );
    this.setState(
      produce(this.state(), (draft: EntryState) => {
        entries.forEach((e) => {
          const pK = KU.stringFromKey(e as PL2.EntryId);
          const input = isDelayed
            ? PendingOperation.DelayCreateContainer
            : PendingOperation.Create;
          const modification = this.operationStateMachine.nextState(
            input,
            PendingOperation.None,
          );
          modification.modify(e, draft);
          draft[pK]._ts = ts;
        });
      }),
    );
    return response;
  }

  update(request: APIM.EntryUpdateApiRequest | APIM.EntryBatchUpdateRequest) {
    const ts = U.timestamp();
    let entries = (request as APIM.EntryBatchUpdateRequest)
      .es as Array<PL2.AnyPartialEntryWithId>;
    if (!entries) {
      entries = [
        (request as APIM.EntryUpdateApiRequest).e,
      ] as Array<PL2.AnyPartialEntryWithId>;
    }
    this.setState(
      produce(this.state(), (draft: EntryState) => {
        entries.forEach((entry) => {
          entry.uAt = ts;
          const pK = KU.stringFromKey(entry as PL2.EntryId);
          const modification = this.operationStateMachine.nextState(
            PendingOperation.Update,
            draft[pK]._pO,
          );
          modification.modify(entry as PL2.AnyPartialEntryWithId, draft);
          draft[pK]._ts = ts;
        });
      }),
    );
  }

  delete(
    request: APIM.EntryDeepDeleteRequest | APIM.EntryDeleteRequest,
    isUndoable = false,
  ) {
    const ts = U.timestamp();
    if ('id' in request) {
      const ddr = request as APIM.EntryDeepDeleteRequest;
      this.setState(
        produce(this.state(), (draft: EntryState) => {
          const input = isUndoable
            ? PendingOperation.UndoableDelete
            : PendingOperation.Delete;
          const pK = KU.stringFromKey(ddr.id);
          const modification = this.operationStateMachine.nextState(
            input,
            draft[pK]._pO,
          );
          modification.modify(ddr.id, draft);
          draft[pK]._ts = ts;
        }),
      );
    } else {
      // EntryDeleteRequest
      const dr = request as APIM.EntryDeleteRequest;
      this.setState(
        produce(this.state(), (draft: EntryState) => {
          const input = isUndoable
            ? PendingOperation.UndoableDelete
            : PendingOperation.Delete;
          dr.e.forEach((entry: PL2.AnyPartialEntryWithId) => {
            const pK = KU.stringFromKey(entry);
            const modification = this.operationStateMachine.nextState(
              input,
              draft[pK]._pO,
            );
            modification.modify(entry, draft);
            draft[pK]._ts = ts;
          });
        }),
      );
    }
  }

  destroyEntries(pKs: string[]) {
    if (U.isEmpty(pKs)) {
      return;
    }
    this.setState(
      produce(this.state(), (draft: EntryState) => {
        pKs.forEach((pK) => {
          if (draft[pK]) {
            delete draft[pK];
            const pPK = KU.parentPK(pK);
            const pCE = draft[pPK];
            if (!U.isEmpty(pCE)) {
              pCE.c = pCE.c.filter((cPK) => cPK !== pK);
            }
          }
        });
      }),
    );
  }

  undo(request: APIM.EntryDeepDeleteRequest) {
    const ts = U.timestamp();
    this.setState(
      produce(this.state(), (draft: EntryState) => {
        const pK = KU.stringFromKey(request.id);
        const modification = this.operationStateMachine.nextState(
          PendingOperation.Undo,
          draft[pK]._pO,
        );
        modification.modify(request.id, draft);
        draft[pK]._ts = ts;
      }),
    );
  }

  modifyState(changes: PL2.AnyPartialEntryWithId[]) {
    this.setState(
      produce(this.state(), (draft: EntryState) => {
        changes.forEach((c) => {
          const pK = KU.stringFromKey(c as PL2.EntryId);
          const ce = this.state()[pK];
          if (ce) {
            if (c.isA) {
              const receiveUpdateStrategy = new ReceiveUpdateStrategy();
              receiveUpdateStrategy.modify(c, draft);
              draft[pK]._ts = U.timestamp();
              if (
                ce.e.uAt === c.uAt &&
                !!ce.e.isA /* it may already have been deleted */
              ) {
                const pPK = KU.parentPK(pK);
                const pCE = draft[pPK];
                if (pCE && !pCE.c.includes(pK)) {
                  pCE.c.push(pK);
                }
              }
            } else if (ce.e.isA) {
              const deleteStrategy = new ReceiveDeleteStrategy();
              deleteStrategy.modify(c, draft);
              draft[pK]._ts = U.timestamp();
            }
          } else if (c.isA) {
            const createStrategy = new ReceiveCreateStrategy();
            createStrategy.modify(c, draft);
            draft[pK]._ts = U.timestamp();
          }
        });
      }),
    );
  }

  clearEntryChange(entries: PL2.AnyEntry[] | PL2.EntryId[]) {
    this.setState(
      produce(this.state(), (draft: EntryState) => {
        entries.forEach((e) => {
          const pK = KU.stringFromKey(e);
          if (draft[pK]) {
            if (draft[pK]._pO === PendingOperation.Delete) {
              delete draft[pK];
            } else {
              draft[pK]._pO = PendingOperation.None;
              draft[pK]._payload = null;
            }
          }
        });
      }),
    );
  }

  advanceCachedEntryState(
    entries: Array<PL2.AnyPartialEntryWithId>,
    input: PendingOperation,
    err?: APIError.Error,
  ) {
    if (U.isEmpty(entries)) {
      return;
    }
    this.setState(
      produce(this.state(), (draft: EntryState) => {
        entries.forEach((e) => {
          const pK = KU.stringFromKey(e as PL2.EntryId);
          if (!U.isEmpty(draft[pK])) {
            const modification = this.operationStateMachine.nextState(
              input,
              draft[pK]._pO,
            );
            modification.modify(e, draft, err);
          }
        });
      }),
    );
  }

  getCachedEntry(pK: string): CachedEntry {
    return this.state()[pK];
  }

  subState(aPOs: PendingOperation[]): EntryState {
    const pOCE = {};
    Object.values(this.state()).forEach((ce) => {
      if (aPOs.includes(ce._pO)) {
        const pK = KU.stringFromKey(ce.e as PL2.EntryId);
        pOCE[pK] = ce;
      }
    });
    return pOCE;
  }
}
